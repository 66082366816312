/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60">
    <title>Users</title>
    <g id="BrPEUV.tif">
      <path
        fill={isActive ? '#E33A34' : '#3c4968'}
        d="M54.38,9.64V31.88c0,.13,0,.26,0,.4,0,2.09-.05,4.17,0,6.26a11,11,0,0,1-1.43,5.57,28.61,28.61,0,0,1-10,10.36,53.35,53.35,0,0,1-12.32,5.44,2.11,2.11,0,0,1-1.18,0,51.78,51.78,0,0,1-7.36-2.73A33.43,33.43,0,0,1,8.61,46.44a14.74,14.74,0,0,1-2.75-6.17c-.11-.62-.16-1.26-.24-1.88V37c0-.15,0-.31,0-.46,0-1.37.07-2.74.07-4.12q0-11.15,0-22.3c0-.15,0-.3,0-.49A38,38,0,0,0,18.8,7.08,35.83,35.83,0,0,0,30,0c.55.47,1.08.94,1.62,1.39A35.26,35.26,0,0,0,52.38,9.52ZM34.09,36.34l.32-.22a21.12,21.12,0,0,0,3.69-3A10.19,10.19,0,0,0,41,23.92a11.18,11.18,0,0,0-16.17-8,10.7,10.7,0,0,0-5.91,9A10.73,10.73,0,0,0,24,35.13c.59.39,1.23.72,1.84,1.07-.78.26-1.56.49-2.34.77a18.67,18.67,0,0,0-10.21,8.41.43.43,0,0,0,.05.58,28.86,28.86,0,0,0,13.28,9,9.55,9.55,0,0,0,6.34.18c1.18-.4,2.33-.89,3.47-1.39A26.7,26.7,0,0,0,46.69,46a.4.4,0,0,0,0-.55A19.37,19.37,0,0,0,36.31,37C35.59,36.79,34.85,36.58,34.09,36.34Z"
      />
    </g>
  </svg>
);

const UsersIcon = memo(SvgComponent);
export default UsersIcon;
