import React from 'react';
import {useTranslation} from 'react-i18next';
import './PaymentForm.scss';
import {useForm, Controller} from 'react-hook-form';
import {FormControl} from '@mui/material';
import {SnackBarConfig} from '@utils//SnackBarConfig';
import {useSnackbar} from 'notistack';
import {PaymentFormParams} from 'src/services/service-mappers/PublicServiceMapper';
import NextUpPerformanceFullLogo from '@components/icons-component/NextUpPerformanceFullLogo';
import TextFieldComponent from '@components/mui-library-components/TextFieldComponent';
import ButtonComponent from '@components/mui-library-components/ButtonComponent';
import LinkComponent from '@components/mui-library-components/LinkComponent';
import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import {useNavigate} from 'react-router-dom';

const PaymentForm = (): React.ReactElement => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  type Data = {
    card_name: string;
    card_number: string;
    expiry_month: string;
    cvv: string;
  };
  const {
    control,
    handleSubmit
    // formState: {errors}
  } = useForm<Data>({
    mode: 'all',
    resolver: async values => {
      return {values, errors: {}};
    }
  });

  const handleFormSubmit = async (values: PaymentFormParams) => {
    try {
      navigate(`/${PUBLIC_ROUTES.SCHOOL}`);
    } catch (e) {
      enqueueSnackbar((e as Error).message, SnackBarConfig('e'));
    }
  };
  const navigatetoSchoolPage = () => {
    navigate(`/${PUBLIC_ROUTES.SCHOOL}`);
  };

  return (
    <div className="payment-form">
      <div className="next-up-logo">
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <legend className="legend-header">
          {t('PRIVATE.LOG_IN.SCHOOLS_PAYMENT')}
        </legend>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.CARD_NAME')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field">
              <Controller
                name="card_name"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    type="text"
                    className="input-field-styles"
                    placeholder="Card Name"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.CARD_NUMBER')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field">
              <Controller
                control={control}
                name="card_number"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    type="number"
                    className="input-field-styles"
                    placeholder="Card Number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={'date-cvv-fields'}>
            <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
              <div className="item-label-input">
                <label className="input-label">
                  {t('PRIVATE.LOG_IN.EXPIRY_MONTH')}
                </label>
              </div>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className="sign-up-input-field">
                <Controller
                  name="expiry_month"
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <TextFieldComponent
                      type="date"
                      className="input-field-styles"
                      placeholder="______ __"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
              <div className="item-label-input">
                <label className="input-label">{t('PRIVATE.LOG_IN.CVV')}</label>
              </div>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className="sign-up-input-field">
                <Controller
                  name="cvv"
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <TextFieldComponent
                      type="password"
                      className="input-field-styles"
                      placeholder="CVV"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <ButtonComponent
            type="submit"
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            className={['login-button-styles', 'login-button-font-styles'].join(
              ' '
            )}>
            {t('PRIVATE.SUBMIT')}
          </ButtonComponent>
          <div className={['link-text', 'link-text-local-styles'].join(' ')}>
            <LinkComponent
              className="link-text"
              component="button"
              onClick={navigatetoSchoolPage}>
              {t('PRIVATE.BACK')}
            </LinkComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
