import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface VisibilityIconComponentProps {
  className?: string;
  value?: string | undefined;
}

const VisibilityOffIconComponent = (
  props: VisibilityIconComponentProps
): React.ReactElement => {
  const {className, value} = props;

  return (
    <VisibilityOffIcon
      className={className}
      style={{
        visibility: value ? 'visible' : 'hidden'
      }}
    />
  );
};

export default VisibilityOffIconComponent;
