import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import {baseEnvironment, config} from '../../config/environment/environment';

import {
  ForgotPasswordParams,
  LoginParams,
  LoginResponseMapper,
  UserExistParams,
  UserCreationParams,
  SchoolUserParams,
  UserCreationParamsMapper,
  UserExistServiceMapper,
  RegionServiceParams,
  SchoolExistServiceParams,
  SchoolExistServiceMapper,
  AdminTokenParams,
  AdminSignupFieldsParams,
  AdminSignupFieldParamsService,
  AdminTokenParamsService
} from '../service-mappers/PublicServiceMapper';
import ServiceRequest from '../ServiceRequest';

const loginUrl = `${config.BASE_URL}/auth/${PUBLIC_ROUTES.LOGIN}`;
const forgotUrl = `${config.BASE_URL}/auth/${baseEnvironment.LOGIN.FORGOT}`;
const isUserExistUrl = `${config.BASE_URL}/v/1/user/${baseEnvironment.LOGIN.IS_USER_EXIST}`;
const stateUrl = `${config.BASE_URL}/v/1/${baseEnvironment.LOGIN.STATE}?`;
const regionUrl = `${config.BASE_URL}/v/1/${baseEnvironment.LOGIN.REGION}`;
const schoolUrl = `${config.BASE_URL}/v/1/${baseEnvironment.LOGIN.SCHOOL_EXIST}`;
const userCreationUrl = `${config.BASE_URL}/v/1/${baseEnvironment.LOGIN.USER}`;
const schoolUserUrl = `${config.BASE_URL}/v/1/${baseEnvironment.LOGIN.SCHOOL}`;
const adminTokenCheckUrl = `${config.BASE_URL}/v/2/${baseEnvironment.ADMIN.AUTH}/${baseEnvironment.ADMIN.SIGNUP}/${baseEnvironment.ADMIN.ADMIN}`;
const adminSignUpUrl = `${config.BASE_URL}/v/2/${baseEnvironment.ADMIN.AUTH}/${baseEnvironment.ADMIN.SIGNUP}/${baseEnvironment.ADMIN.ADMIN}`;
const updateUserPasswordUrl =
`${config.BASE_URL}/v/2/${baseEnvironment.USER.AUTH}/${baseEnvironment.USER.USER}/${baseEnvironment.USER.UPDATE}/${baseEnvironment.USER.PASSWORD_RESET}`;
export const loginService = async (
  values: LoginParams
): Promise<LoginResponseMapper> => {
  return ServiceRequest.post(loginUrl, {
    payload: values
  });
};

// * TODO: Rename to include suffix Service
export const forgotPassword = async (
  values: ForgotPasswordParams
): Promise<LoginResponseMapper> => {
  return ServiceRequest.post(forgotUrl, {
    payload: values
  });
};

// * TODO: Rename to include suffix Service
export const isUserExistService = async (
  values: UserExistParams
): Promise<UserExistServiceMapper> => {
  return ServiceRequest.post(isUserExistUrl, {
    payload: values
  });
};

// * TODO: Rename to include suffix Service
export const stateService = async (): Promise<never[]> => {
  return ServiceRequest.get(stateUrl);
};

// * TODO: Rename to include suffix Service
export const regionService = async (
  values: RegionServiceParams
): Promise<never[]> => {
  const url = regionUrl + `/${values.id}`;
  return ServiceRequest.get(url);
};

// * TODO: Rename to include suffix Service
export const isSchoolExistService = async (
  values: SchoolExistServiceParams
): Promise<SchoolExistServiceMapper> => {
  const url = schoolUrl + `?school_name=${values.school_name}`;
  return ServiceRequest.get(url);
};

// * TODO: Rename to include suffix Service
export const userService = async (
  values: UserCreationParams
): Promise<UserCreationParamsMapper> => {
  return ServiceRequest.post(userCreationUrl, {
    payload: values
  });
};

// * TODO: Rename to include suffix Service
export const schoolUserService = async (
  params: SchoolUserParams
): Promise<SchoolExistServiceMapper> => {
  const url =
    schoolUserUrl +
    `/${params.user_id}?active=${params.active}&page=${params.page}&size=${params.size}`;
  return ServiceRequest.get(url);
};

export const adminTokenCheckService = async (
  params: AdminTokenParams
): Promise<AdminTokenParamsService> => {
  const url = adminTokenCheckUrl + `/${params.token}`;
  return ServiceRequest.get(url);
};

export const adminSignUpService = async (
  values: AdminSignupFieldsParams
): Promise<AdminSignupFieldParamsService> => {
  return ServiceRequest.post(adminSignUpUrl, {
    payload: values
  });
};

export const tokenValidation = async (
  token: string
): Promise<{
  status: number;
  user: {email: string; type: string}[];
  message: string;
}> => {
  const url = `${config.BASE_URL}/v/2/auth/validate-password-reset/${token}`;
  return ServiceRequest.post(url, {payload: token});
};

export const setPassword = async (payload: {
  password: string;
  confirm: string;
}): Promise<{status: number; message: string}> => {
  const url = `${config.BASE_URL}/auth/set`;
  return ServiceRequest.put(url, {payload: payload});
};

export const updateUserPassword = async (
  values: AdminSignupFieldsParams
): Promise<AdminSignupFieldParamsService> => {
  return ServiceRequest.post(updateUserPasswordUrl, {
    payload: values
  });
};