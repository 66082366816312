import {Select} from '@mui/material';

interface ButtonComponentParams {
  selectOptions: {label: string; value: string; name?: string}[];
  value?: string | number | readonly string[] | undefined;
  // eslint-disable-next-line
  onChange?: any;
  placeholder: string;
  defaultText: string;
  extension?: string;
  className?: string;
  multiple?: boolean;
}

const SelectComponent = (props: ButtonComponentParams): React.ReactElement => {
  const {
    value,
    selectOptions,
    onChange,
    placeholder,
    defaultText,
    extension,
    className,
    multiple
  } = props;
  return (
    <div>
      <Select
        multiple={multiple}
        native
        className={className}
        placeholder={placeholder}
        id="demo-customized-select-native"
        value={value}
        onChange={onChange}>
        <option value={defaultText} disabled>
          {defaultText}
        </option>
        {selectOptions.map((data, index: number) => {
          return (
            <option
              key={index}
              value={extension === 'label' ? data.label : data.name}
              className="list-item">
              {extension === 'label' ? data.label : data.name}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectComponent;
