import {CircularProgress} from '@mui/material';
import React from 'react';

interface CircularProgressComponentProps {
  className?: string;
}
const CircularProgressComponent = (
  props: CircularProgressComponentProps
): React.ReactElement => {
  const {className} = props;
  return <CircularProgress className={className} />;
};

export default CircularProgressComponent;
