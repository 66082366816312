import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm, Controller} from 'react-hook-form';
import {FormControl, FormHelperText, IconButton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {SnackBarConfig} from '@utils//SnackBarConfig';
import {AdminSignupParams} from 'src/services/service-mappers/PublicServiceMapper';
import TextFieldComponent from '@components/mui-library-components/TextFieldComponent';
import ButtonComponent from '@components/mui-library-components/ButtonComponent';
import CloseIconComponent from '@components/icons-component/CloseIconComponent';
import CircularProgressComponent from '@components/mui-library-components/CircularProgressComponent';
import {AdminSignupValidator} from './AdminSignupValidator';
import './AdminSignup.scss';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  adminSignUpService,
  adminTokenCheckService
} from 'src/services/service-handlers/PublicService';
import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import NextUpPerformanceFullLogo from '@components/icons-component/NextUpPerformanceFullLogo';
import VisibilityIconComponent from '@components/mui-library-components/VisibilityIcon';
import VisibilityOffIconComponent from '@components/mui-library-components/VisibilityOffIcon';

interface LoginProps {
  pathName?: string;
}
const AdminSignup = (props: LoginProps): React.ReactElement => {
  const location = useLocation();
  const tokenParam = new URLSearchParams(location.search).get('token'); // to fetch the token with comes as a query param in URL
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false); // To show the loader in login button
  const [userData, setUserData] = useState({
    email: ''
  }); // To show the loader in login button

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);

  type Data = {
    admin_email?: string;
    admin_password: string;
    admin_confirm_password: string;
    user_handle: string;
  };
  // validation
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<Data>({
    defaultValues: {
      admin_password: '',
      admin_confirm_password: '',
      user_handle: ''
    },
    mode: 'all',
    resolver: async values => {
      return {values, errors: AdminSignupValidator(values)};
    }
  });

  const handleFormSubmit = async (values: AdminSignupParams) => {
    try {
      // making loader state to false after 3 seconds
      setLoader(true);
      if (userData.email) {
        adminSignUpService({
          email: userData.email,
          password: values.admin_password,
          confirm: values.admin_confirm_password,
          userHandle: values.user_handle
        })
          .then(res => {
            if (res.status === 200) {
              setLoader(false);
              // If the status is 200, then redirect to admin login page after displaying the success toaster message
              enqueueSnackbar(res.message, SnackBarConfig('s'));
              navigate(`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`);
            } else {
              setLoader(false);
              enqueueSnackbar(res.message, SnackBarConfig('e'));
            }
          })
          .catch(err => {
            setLoader(false);
            enqueueSnackbar((err as Error).message, SnackBarConfig('e'));
          });
      }
    } catch (e) {
      setLoader(false);
      // Handle error
      enqueueSnackbar((e as Error).message, SnackBarConfig('e'));
    }
  };

  const resetFieldValues = () => {
    reset(formValues => ({
      ...formValues,
      admin_password: '',
      admin_confirm_password: '',
      user_handle: ''
    }));
  };
  useEffect(() => {
    resetFieldValues();
    if (tokenParam) {
      adminTokenCheckService({
        token: tokenParam
      })
        .then(res => {
          if (res.status === 200) {
            setUserData(res.data[0]);
          } else {
            enqueueSnackbar(res?.message, SnackBarConfig('e'));
            navigate(`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`);
          }
        })
        .catch(err => {
          enqueueSnackbar((err as Error).message, SnackBarConfig('e'));
          navigate(`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowPassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
    } else {
      setIsPasswordVisible(true);
    }
  };

  const handleClickShowConfirmPassword = () => {
    if (isConfirmPasswordVisible) {
      setIsConfirmPasswordVisible(false);
    } else {
      setIsConfirmPasswordVisible(true);
    }
  };

  return (
    <div className="organization-signup">
      <div className={['next-up-logo', 'next-up-logo-local-styles'].join(' ')}>
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <legend className="legend-header">{t('PRIVATE.ADMIN_SIGN_UP')}</legend>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.EMAIL')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-disabled-field">
              <Controller
                control={control}
                name="admin_email"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type="email"
                    placeholder="Email"
                    value={userData.email}
                    disabled={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              email: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={['password-field', 'item-parent-div'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.USER_HANDLE')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={[
                'login-input-field',
                'login-input-password-local-styles'
              ].join(' ')}
              error={!!errors.user_handle}>
              <Controller
                control={control}
                name="user_handle"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type={'text'}
                    placeholder="User Name"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              user_handle: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.user_handle && (
                <FormHelperText className="error-text-component">
                  {t(errors.user_handle as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['password-field', 'item-parent-div'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.PASSWORD')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={[
                'login-input-field',
                'login-input-password-local-styles'
              ].join(' ')}
              error={!!errors.admin_password}>
              <Controller
                control={control}
                name="admin_password"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className="input-props-parent-div">
                          <IconButton
                            onClick={handleClickShowPassword}
                            className="visibility-icon-local-styles">
                            {isPasswordVisible ? (
                              <VisibilityIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            ) : (
                              <VisibilityOffIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            )}
                          </IconButton>

                          <CloseIconComponent
                            value={value}
                            className="close-icon"
                            onClick={() => {
                              setIsPasswordVisible(false);
                              reset(formValues => ({
                                ...formValues,
                                admin_password: ''
                              }));
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.admin_password && (
                <FormHelperText className="error-text-component">
                  {t(errors.admin_password as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['password-field', 'item-parent-div'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.CONFIRM_PASSWORD')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={[
                'login-input-field',
                'login-input-password-local-styles'
              ].join(' ')}
              error={!!errors.admin_confirm_password}>
              <Controller
                control={control}
                name="admin_confirm_password"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className="input-props-parent-div">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            className="visibility-icon-local-styles">
                            {isConfirmPasswordVisible ? (
                              <VisibilityIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            ) : (
                              <VisibilityOffIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            )}
                          </IconButton>
                          <CloseIconComponent
                            value={value}
                            className="close-icon"
                            onClick={() => {
                              setIsConfirmPasswordVisible(false);
                              reset(formValues => ({
                                ...formValues,
                                admin_confirm_password: ''
                              }));
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.admin_confirm_password && (
                <FormHelperText className="error-text-component">
                  {t(errors.admin_confirm_password as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="login-button-parent-div">
            <ButtonComponent
              type="submit"
              onClick={handleSubmit(handleFormSubmit)}
              variant="contained"
              disabled={loader}
              className={[
                'login-button-styles',
                'login-button-font-styles'
              ].join(' ')}>
              {loader ? (
                <CircularProgressComponent className="circular-progress-color" />
              ) : (
                t('PRIVATE.ORG_SIGN_UP')
              )}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminSignup;
