import { MAX_CHAR_LIMIT_FOR_USER_HANDLE } from '@constants/AppConstants';
import {FieldValues} from 'react-hook-form';

export const AdminSignupValidator = (
  values: FieldValues
): FieldValues => {
  let errors: FieldValues = {
    admin_password: '',
    admin_confirm_password: '',
    user_handle: ''
  };
  if (!values.admin_password || !values.admin_password.replace(/\s/g, '').length) {
    errors.admin_password = 'VALIDATORS.PASSWORD_REQUIRED';
  }
  if (values.admin_confirm_password !== values.admin_password) {
    errors.admin_confirm_password = 'VALIDATORS.PASSWORD_MATCH';
  }

  if (!values.user_handle || !values.user_handle.replace(/\s/g, '').length) {
    errors.user_handle = 'VALIDATORS.USER_HANDLE_REQUIRED';
  }

  if (values.user_handle && values.user_handle.length > MAX_CHAR_LIMIT_FOR_USER_HANDLE) {
    errors.user_handle = 'VALIDATORS.USER_NAME_MAX_LENGTH';
  }

  if (!errors.admin_password && !errors.admin_confirm_password && !errors.user_handle) {
    errors = {};
  }
  return errors;
};
