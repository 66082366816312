import Storage from './Storage';

class AuthUtils {
  static getAuthToken(): string | null {
    return Storage.getItem(Storage.KEYS.AUTH_TOKEN);
  }
  static initiatePortal(portal: string): void {
    Storage.setItem(Storage.KEYS.PORTAL, portal);
  }
  static resolvePortal(): string | null {
    return Storage.getItem(Storage.KEYS.PORTAL);
  }
  static clearAuthToken(): void {
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
  }

  static setAuthToken(token: string, portal: string): void {
    this.clearAuthToken();
    Storage.setItem(Storage.KEYS.AUTH_TOKEN, token);
    this.initiatePortal(portal);
  }

  static clearUserAccess(): void {
    Storage.removeItem(Storage.KEYS.USER_GROUP);
  }

  static setUserAccess(name: string): void {
    this.clearUserAccess();
    Storage.setItem(Storage.KEYS.USER_GROUP, name);
  }

  static getUserAccess(): string | null {
    return Storage.getItem(Storage.KEYS.USER_GROUP);
  }

  static clearSelectedLoginType(): void {
    Storage.removeItem(Storage.KEYS.SELECTED_LOGIN_TYPE);
  }

  static setSelectedLoginType(type: string): void {
    this.clearSelectedLoginType();
    Storage.setItem(Storage.KEYS.SELECTED_LOGIN_TYPE, type);
  }

  static clearStorage(): void {
    Storage.clear();
  }
}

export default AuthUtils;
