import {createSlice} from '@reduxjs/toolkit';

import {ContractsStateType} from './ContractStateTypes';

const initialState: ContractsStateType = {
  contractId: null,
  orgName: '',
  userName: '',
  userProfilePic: '',
  orgRegion: -1,
  userRegion: -1,
  prizeValue: 0,
  adminTabValue: '',
  awardedTo: 0,
  contractTitle: '',
  contractDescription: '',
  contractVideoId: -1,
  suggestedPrize: '',
  selectedWinnersCount: 0,
  athleteEmail: '',
  contractCreatedBy: 0,
  heicLink: '',
  heicBase64String: '',
  isContractAwarded: false,
  isContractUpdateButtonClicked: false,
  adminContractsPage: 0,
  challengeSubmissionWeburl: '',
  challengeSubmissionVideoThumbnail: '',
  challengeVideoSubmissionId: -1,
  organizationProfilePic: null
};

const contracts = createSlice({
  initialState,
  name: 'contracts',
  reducers: {
    contractDetails: (state, param) => {
      const contractParams = param?.payload;
      state.contractId = contractParams?.contract_id;
      state.orgName = contractParams?.org_name;
      state.userName = contractParams?.user_name;
      state.userProfilePic = contractParams?.user_profile_pic;
      (state.userRegion = contractParams?.user_region),
        (state.orgRegion = contractParams?.org_region),
        (state.prizeValue = contractParams?.prize_value),
        (state.adminTabValue = contractParams?.tab_value);
      (state.awardedTo = contractParams?.awarded_to),
        (state.contractTitle = contractParams?.contract_title);
      state.contractDescription = contractParams?.description;
      state.contractVideoId = contractParams?.contract_video_id;
      state.suggestedPrize = contractParams?.suggested_prize;
      state.selectedWinnersCount = contractParams?.selected_winners_count;
      state.contractCreatedBy = contractParams?.contract_created_by;
      state.athleteEmail = contractParams?.athlete_email;
      state.adminContractsPage = contractParams?.admin_contracts_page;
      state.organizationProfilePic = contractParams?.org_profile_pic;
    },
    heicProfilePicDetails: (state, param) => {
      const profilePicLink = param?.payload;
      state.heicLink = profilePicLink?.profile_link;
      state.heicBase64String = profilePicLink?.heic_base64_string;
    },
    contractAwarded: (state, param) => {
     state.isContractAwarded = param?.payload?.is_contract_awarded;
    },
    contractPortalUpdate: (state, param) => {
      state.isContractUpdateButtonClicked = param?.payload?.is_contract_update_button_clicked;
     },
    challengeSubmissionVideoDetails: (state, param) => {
      state.challengeSubmissionWeburl = param?.payload?.challenge_submission_web_url;
      state.challengeSubmissionVideoThumbnail = param?.payload?.challenge_submission_video_thumbnail;
      state.challengeVideoSubmissionId = param?.payload?.challenge_video_submission_id;
     },
  }
});

export const {contractDetails, heicProfilePicDetails,contractAwarded,contractPortalUpdate,challengeSubmissionVideoDetails} = contracts.actions;
export default contracts.reducer;
