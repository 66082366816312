/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <title>Sports</title>
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M16.612,14.499 C14.7875522,12.8857984 12.4353683,11.9967924 9.99999613,12 C7.56463168,11.9967924 5.21244775,12.8857984 3.388,14.499 C2.48225423,13.173604 1.99841773,11.6053185 1.99999613,10 C1.99999613,8.334 2.513,6.785 3.388,5.502 C5.21249684,7.11500084 7.56472857,8.00366914 9.99999613,8 C12.4352714,8.00366914 14.7875032,7.11500084 16.612,5.502 C17.5176099,6.82708713 18.001443,8.39501303 17.9999961,10 C18.0015823,11.6053185 17.5177458,13.173604 16.612,14.499 M10,17.9999971 C8.05310737,18.0016473 6.17303053,17.2901708 4.715,15.9999971 C6.17303053,14.7098292 8.05310737,13.9983527 10,13.9999971 C12.024,13.9999971 13.875,14.756 15.285,15.9999971 C13.8269695,17.2901708 11.9468926,18.0016473 10,17.9999971 M10,1.99999715 C12.024,1.99999715 13.875,2.756 15.285,3.99999715 C13.8269695,5.29017077 11.9468926,6.00164734 10,5.99999715 C8.05310737,6.00164734 6.17303053,5.29017077 4.715,3.99999715 C6.17303053,2.70982923 8.05310737,1.99835266 10,1.99999715 M10,-4.4408921e-16 C4.484,-4.4408921e-16 0,4.472 0,10 C0,15.519 4.474,20 10,20 C15.522,20 20,15.522 20,10 C20,4.49 15.535,-4.4408921e-16 10,-4.4408921e-16"
      id="Shape"
    />
  </svg>
);

const SportsIcon = memo(SvgComponent);
export default SportsIcon;
