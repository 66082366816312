/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60">
    <title>Organizations</title>
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      x="4"
      y="46"
      width="52"
      height="6"
      rx="1"
      ry="1"
    />
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      y="54"
      width="60"
      height="6"
      rx="1"
      ry="1"
    />
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      x="8"
      y="24"
      width="6"
      height="20"
      rx="1"
      ry="1"
    />
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      x="20"
      y="24"
      width="6"
      height="20"
      rx="1"
      ry="1"
    />
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      x="34"
      y="24"
      width="6"
      height="20"
      rx="1"
      ry="1"
    />
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      x="46"
      y="24"
      width="6"
      height="20"
      rx="1"
      ry="1"
    />
    <rect
      fill={isActive ? '#E33A34' : '#3c4968'}
      x="2"
      y="14"
      width="56"
      height="8"
      rx="1"
      ry="1"
    />
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M3,12H57a1,1,0,0,0,.23-2L30.35.06a1,1,0,0,0-.7,0l-27,10A1,1,0,0,0,3,12Z"
    />
  </svg>
);

const TeamsIcon = memo(SvgComponent);
export default TeamsIcon;
