import CloseIconComponent from "@components/icons-component/CloseIconComponent";
import NextUpPerformanceFullLogo from "@components/icons-component/NextUpPerformanceFullLogo";
import ButtonComponent from "@components/mui-library-components/ButtonComponent";
import CircularProgressComponent from "@components/mui-library-components/CircularProgressComponent";
import TextFieldComponent from "@components/mui-library-components/TextFieldComponent";
import { PUBLIC_ROUTES } from "@constants/RouteConstants";
import { FormControl, FormHelperText, IconButton } from "@mui/material";
import { SnackBarConfig } from "@utils//SnackBarConfig";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Services from "src/services/Services";
import { tokenValidation, updateUserPassword } from "src/services/service-handlers/PublicService";
import { useTranslation } from "react-i18next";
import './UpdateUser.scss';
import VisibilityIconComponent from '@components/mui-library-components/VisibilityIcon';
import VisibilityOffIconComponent from '@components/mui-library-components/VisibilityOffIcon';
import { ResetPasswordValidator } from "../resetpassword/ResetPasswordValidator";
const UpdateUser = ():React.ReactElement => {
type Data = {
        email?: string;
        password: string;
        confirm: string;
    };
const {enqueueSnackbar} = useSnackbar();
const navigate = useNavigate();
const {t} = useTranslation();
const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false);
const tokenParam = new URLSearchParams(location.search).get('tt');
const [userData, setUserData] = useState({
 email: '',
 type: ''
}); // To show the loader in login button
const [loader, setLoader] = useState<boolean>(false);
const {
        control,
        handleSubmit,
        formState: {errors},
        reset
      } = useForm<Data>({
        defaultValues: {
          password: '',
          confirm: ''
        },
        mode: 'all',
        resolver: async values => {
          return {values, errors: ResetPasswordValidator(values)};
        }
      });
     const resetFieldValues = () => {
        reset(formValues => ({
          ...formValues,
          password: '',
          confirm: ''
        }));
      };
    useEffect(() => {
      resetFieldValues();
      if (tokenParam) {
        // Validating token param to get the user data
        tokenValidation(tokenParam)
          .then(res => {
            if (res.status === 200) {
            setUserData(res.user[0]);
              Services.initAfterAuth({token: tokenParam});
            } else {
              enqueueSnackbar(res?.message, SnackBarConfig('e'));
              navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
            }
          })
          .catch(err => {
            enqueueSnackbar((err as Error).message, SnackBarConfig('e'));
            navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
          });
      }
      else if(!tokenParam) {
        enqueueSnackbar(t('PRIVATE.LOG_IN.NO_TOKEN'), SnackBarConfig('e'));
        navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
      const handleFormSubmit = async (values: {
        password: string;
        confirm: string;
      }) => {
        try {
            // Calling updateUserPassword API to reset the password
            setLoader(true);
             updateUserPassword(
                {
                    email: userData.email,
                    password: values.password,
                    confirm: values.confirm
                }
             ).then((res) => {
                if(res.status === 200) {
                setLoader(false);
                  enqueueSnackbar(res.message, SnackBarConfig('s'));
                  navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
                }
                else {
                setLoader(false);
                 // Handle error
                  enqueueSnackbar(res.message, SnackBarConfig('e'));
                }
              }).catch(err => {
                setLoader(false);
                // Handle error
                enqueueSnackbar((err as Error).message, SnackBarConfig('e'));
              });
        }
        catch (e) {
          setLoader(false);
          // Handle error
          enqueueSnackbar((e as Error).message, SnackBarConfig('e'));
        }
      };
const handleClickShowPassword = () => {
if (isPasswordVisible) {
    setIsPasswordVisible(false);
} else {
    setIsPasswordVisible(true);
}
};
const handleClickShowConfirmPassword = () => {
if (isConfirmPasswordVisible) {
    setIsConfirmPasswordVisible(false);
} else {
    setIsConfirmPasswordVisible(true);
}
};
  return (
    <div className="update-user">
      <div className={['next-up-logo', 'next-up-logo-local-styles'].join(' ')}>
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <legend className="legend-header">{'Reset Password'}</legend>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.EMAIL')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-disabled-field">
              <Controller
                control={control}
                name="email"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type="email"
                    placeholder="Email"
                    value={userData.email}
                    disabled={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              email: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={['password-field', 'item-parent-div'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.PASSWORD')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-field"
              error={!!errors.password}>
              <Controller
                control={control}
                name="password"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className='input-props-parent-div'>
                        <IconButton onClick={handleClickShowPassword} className='visibility-icon-local-styles'>
                        {isPasswordVisible ? (
                          <VisibilityIconComponent
                           className='visibility-icon'
                           value={value}
                          />
                        ) : (
                          <VisibilityOffIconComponent
                           className='visibility-icon'
                           value={value}
                          />
                        )}
                        </IconButton>
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            setIsPasswordVisible(false);
                            reset(formValues => ({
                              ...formValues,
                              password: ''
                            }));
                          }}
                        />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.password && (
                <FormHelperText className="error-text-component">
                  {t(errors.password as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['password-field', 'item-parent-div'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.CONFIRM_PASSWORD')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-field"
              error={!!errors.confirm}>
              <Controller
                control={control}
                name="confirm"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    className="input-field-styles"
                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className='input-props-parent-div'>
                        <IconButton onClick={handleClickShowConfirmPassword} className='visibility-icon-local-styles'>
                        {isConfirmPasswordVisible ? (
                          <VisibilityIconComponent
                           className='visibility-icon'
                           value={value}
                          />
                        ) : (
                          <VisibilityOffIconComponent
                           className='visibility-icon'
                           value={value}
                          />
                        )}
                        </IconButton>
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            setIsConfirmPasswordVisible(false);
                            reset(formValues => ({
                              ...formValues,
                              confirm: ''
                            }));
                          }}
                        />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.confirm && (
                <FormHelperText className="error-text-component">
                  {t(errors.confirm as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="login-button-parent-div">
            <ButtonComponent
              type="submit"
              onClick={handleSubmit(handleFormSubmit)}
              variant="contained"
              disabled={loader}
              className={[
                'login-button-styles',
                'login-button-font-styles'
              ].join(' ')}>
              {loader ? (
                <CircularProgressComponent className="circular-progress-color" />
              ) : (
                'Reset'
              )}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  )
}
export default UpdateUser;