import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

import {languageKeys} from './constants/AppConstants';
import translationCommonEN from './locales/en/en-common.json';
import translationHomeEN from './modules/home/locales/en-home.json';
import translationPublicEN from './modules/public/locales/en-public.json';

const resources = {
  en: {
    translation: {
      ...translationCommonEN,
      ...translationPublicEN,
      ...translationHomeEN
    }
  }
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    compatibilityJSON: 'v3',
    // change key to change the language
    debug: false,

    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    lng: languageKeys.EN,
    resources
  });

export default i18n;
