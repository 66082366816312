import {
  FilledInputProps,
  InputBaseComponentProps,
  OutlinedInputProps,
  TextField
} from '@mui/material';
import React from 'react';
interface TextFieldComponentParams {
  type: string;
  placeholder?: string;
  value?: string | number;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  className?: string;
  inputProps?: InputBaseComponentProps | undefined;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined;
  errorOcurred?: boolean;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  select?: boolean;
  children?: React.ReactElement;
  defaultValue?: string | number;
}
const TextFieldComponent = (
  props: TextFieldComponentParams
): React.ReactElement => {
  const {
    type,
    placeholder,
    value,
    onChange,
    onBlur,
    className,
    inputProps,
    InputProps,
    errorOcurred,
    multiline,
    rows,
    disabled,
    select,
    children,
    defaultValue
  } = props;
  return (
    <TextField
      defaultValue={defaultValue}
      rows={rows}
      multiline={multiline}
      select={select}
      error={errorOcurred}
      type={type}
      InputProps={InputProps}
      inputProps={inputProps}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={className}
      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
      disabled={disabled}>
      {children}
    </TextField>
  );
};

export default TextFieldComponent;
