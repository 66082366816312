import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import './SignUpForm.scss';
import {useForm, Controller} from 'react-hook-form';
import {FormControl, FormHelperText, IconButton} from '@mui/material';
import {SnackBarConfig} from '@utils//SnackBarConfig';
import {useSnackbar} from 'notistack';
import {
  SignUpFormParams,
  UserExistServiceMapper
} from 'src/services/service-mappers/PublicServiceMapper';
import NextUpPerformanceFullLogo from '@components/icons-component/NextUpPerformanceFullLogo';
import TextFieldComponent from '@components/mui-library-components/TextFieldComponent';
import ButtonComponent from '@components/mui-library-components/ButtonComponent';
import LinkComponent from '@components/mui-library-components/LinkComponent';
import {isUserExistService} from 'src/services/service-handlers/PublicService';
import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import {useNavigate} from 'react-router-dom';
import {validateSignUpform} from './SignUpFormValidator';
import {clientRegistrationParams} from '@modules/home/state/auth/AuthStateSlice';
import {useDispatch} from 'react-redux';
import CloseIconComponent from '@components/icons-component/CloseIconComponent';
import CircularProgressComponent from '@components/mui-library-components/CircularProgressComponent';
import VisibilityIconComponent from '@components/mui-library-components/VisibilityIcon';
import VisibilityOffIconComponent from '@components/mui-library-components/VisibilityOffIcon';

const SignUpForm = (): React.ReactElement => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const [errorText, setErrorText] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false); // To show the loader in next button
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);

  type Data = {
    name: string;
    email: string;
    password: string;
    confirm_password: string;
    user_handle: string;
  };
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<Data>({
    mode: 'all',
    resolver: async values => {
      return {values, errors: validateSignUpform(values)};
    }
  });

  const handleFormSubmit = async (values: SignUpFormParams) => {
    try {
      setLoader(true);
      const payload = {
        user_email: values.email,
        user_handle: values.user_handle
      };
      const userInfoParams = {
        isValid: true,
        confirm: values.confirm_password,
        email: values.email,
        password: values.password,
        type: t('PRIVATE.SIDE_NAV.SCHOOL_ADMIN'),
        username: values.name,
        userHandle: values.user_handle
      };
      const response: UserExistServiceMapper = await isUserExistService(
        payload
      );
      if (response.status === 200) {
        setErrorText(false);
        navigate(`/${PUBLIC_ROUTES.SCHOOL}`);
        setLoader(false);
        dispatch(clientRegistrationParams(userInfoParams));
      } else {
        setLoader(false);
        setErrorText(true);
      }
    } catch (e) {
      setLoader(false);
      enqueueSnackbar((e as Error).message, SnackBarConfig('e'));
    }
  };
  const navigateToClientLoginPage = () => {
    navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
  };

  const handleClickShowPassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
    } else {
      setIsPasswordVisible(true);
    }
  };

  const handleClickShowConfirmPassword = () => {
    if (isConfirmPasswordVisible) {
      setIsConfirmPasswordVisible(false);
    } else {
      setIsConfirmPasswordVisible(true);
    }
  };

  return (
    <div className="sign-up-form">
      <div className="next-up-logo">
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <legend className="legend-header">{t('PRIVATE.LOG_IN.SIGNUP')}</legend>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.NAME')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.name}>
              <Controller
                name="name"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.name ? true : false}
                    type="text"
                    className="input-field-styles"
                    placeholder="Full Name"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              name: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.name && (
                <FormHelperText className="error-text-component">
                  {t(errors.name as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.EMAIL')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.email}>
              <Controller
                control={control}
                name="email"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.email ? true : false}
                    type="email"
                    className="input-field-styles"
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              email: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.email && (
                <FormHelperText className="error-text-component">
                  {t(errors.email as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.USER_HANDLE')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.user_handle}>
              <Controller
                name="user_handle"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.user_handle ? true : false}
                    type="text"
                    className="input-field-styles"
                    placeholder="User Name"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              user_handle: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.user_handle && (
                <FormHelperText className="error-text-component">
                  {t(errors.user_handle as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.PASSWORD')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.password}>
              <Controller
                name="password"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.password ? true : false}
                    type={isPasswordVisible ? 'text' : 'password'}
                    className="input-field-styles"
                    placeholder="Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className="input-props-parent-div">
                          <IconButton
                            onClick={handleClickShowPassword}
                            className="visibility-icon-local-styles">
                            {isPasswordVisible ? (
                              <VisibilityIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            ) : (
                              <VisibilityOffIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            )}
                          </IconButton>
                          <CloseIconComponent
                            value={value}
                            className="close-icon"
                            onClick={() => {
                              setIsPasswordVisible(false);
                              reset(formValues => ({
                                ...formValues,
                                password: ''
                              }));
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.password && (
                <FormHelperText className="error-text-component">
                  {t(errors.password as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.CONFIRM_PASSWORD')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.confirm_password}>
              <Controller
                name="confirm_password"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.confirm_password ? true : false}
                    type={isConfirmPasswordVisible ? 'text' : 'password'}
                    className="input-field-styles"
                    placeholder="Confirm Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className="input-props-parent-div">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            className="visibility-icon-local-styles">
                            {isConfirmPasswordVisible ? (
                              <VisibilityIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            ) : (
                              <VisibilityOffIconComponent
                                value={value}
                                className="visibility-icon"
                              />
                            )}
                          </IconButton>
                          <CloseIconComponent
                            value={value}
                            className="close-icon"
                            onClick={() => {
                              setIsConfirmPasswordVisible(false);
                              reset(formValues => ({
                                ...formValues,
                                confirm_password: ''
                              }));
                            }}
                          />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.confirm_password && (
                <FormHelperText className="error-text-component">
                  {t(errors.confirm_password as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
            {errorText && (
              <div
                className={['error-text', 'error-text-local-styles'].join(' ')}>
                {t('PRIVATE.USER_ALREADY_EXISTS')}
              </div>
            )}
          </div>
          <ButtonComponent
            type="submit"
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            disabled={loader}
            className={['login-button-styles', 'login-button-font-styles'].join(
              ' '
            )}>
            {loader ? (
              <CircularProgressComponent className="circular-progress-color" />
            ) : (
              t('PRIVATE.NEXT')
            )}
          </ButtonComponent>
          <div className={['link-text', 'link-text-local-styles'].join(' ')}>
            <LinkComponent
              className="link-text"
              component="button"
              onClick={navigateToClientLoginPage}>
              {t('PRIVATE.BACK_TO_LOGIN')}
            </LinkComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
