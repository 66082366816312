export const BASE_ROUTE = 'StarterApp';
export enum PRIVATE_ROUTES {
  HOME = '/',
  LOGOUT = 'logout',
  DASHBOARD = 'dashboard',
  CLIENT = 'client',
  ADMIN = 'admin',
  LEADERBOARD = 'leaderboard',
  CHALLENGES = 'challenges',
  TEAMS = 'teams',
  USERS = 'users',
  CHALLENGES_FEED = 'challenges-feed',
  EXERCISES = 'exercises',
  REGIONS = 'regions',
  FUNDRAISING = 'fundraiser',
  PROFILE = 'profile',
  ORGANIZATIONS = 'organizations',
  ADMINISTRATORS = 'administrators',
  COACHES = 'coaches',
  SPORTS = 'sports',
  ACTIVITIES = 'activities',
  CONTRACTS = 'contracts',
  SUBMITTED='submitted',
  APPROVED='approved',
  DENIED='denied',
  DETAILS = 'details',
  COMMENTS = 'comments',
  OPEN_CONTRACTS = 'opencontracts',
  AWARDED_CONTRACTS = 'awardedcontracts',
  CONTRACT = 'contract',
  APPLY_CONTRACT = 'applycontract',
  APPLIED_CONTRACT = 'appliedcontract',
  WON_CONTRACTS = 'woncontract',
  ALL = 'all',
  OPEN = 'open',
  APPLIED = 'applied',
  WON = 'won',
  CLOSED = 'closed',
  AWARDED = 'awarded',
  VIEW_SUBMISSIONS = 'viewsubmissions',
  CLIENT_PROFILE_URL='userprofile'
}

export enum PUBLIC_ROUTES {
  LOGIN = 'login',
  LOGOUT = 'logout',
  CLIENT = 'client',
  ADMIN = 'admin',
  FORGOT = 'forgot-password',
  SIGNUP = 'signup',
  IS_USER_EXIST = 'isUserExist',
  SCHOOL = 'school',
  PAYMENT = 'payment',
  CONTRACT = 'contract',
  COACH = 'coach',
  RESET_PASSWORD = 'resetpassword',
  USER= 'user',
  UPDATE='update'
}
