import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logout} from '@modules/home/state/auth/AuthStateSlice';
import AuthUtils from '@utils//AuthUtils';
import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import {RootState} from 'src/store/Store';
import {USER_TYPES} from '@constants/AppConstants';
import {contractAwarded, heicProfilePicDetails} from '@modules/home/state/contracts/ContractStateSlice';

const Logout = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state: RootState) => {
    return state?.auth?.userType;
  });
  useEffect(() => {
    AuthUtils.clearAuthToken();
    dispatch(logout(''));
    dispatch(
      heicProfilePicDetails({
        profile_link: '',
        heic_base64_string: ''
      })
    );
    dispatch(contractAwarded({is_contract_awarded: false}));

    if (userType === PUBLIC_ROUTES.ADMIN) {
      navigate(`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`);
    } else if (
      userType === USER_TYPES.STUDENT_ATHLETE ||
      userType === USER_TYPES.COACH
    ) {
      navigate(`/${PUBLIC_ROUTES.CONTRACT}/${PUBLIC_ROUTES.LOGIN}`);
    } else {
      navigate(`/`);
    }
  }, [dispatch, navigate]); // eslint-disable-line react-hooks/exhaustive-deps
  return <div>Logging Out....</div>;
};

export default Logout;
