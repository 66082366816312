import {PRIVATE_ROUTES} from './RouteConstants';
import RegionsIcon from '@components/icons-component/RegionsIcon';
import ChallengesIcon from '@components/icons-component/ChallengesIcon';
import ChallengesFeedIcon from '@components/icons-component/ChallengesFeedIcon';
import ExercisesIcon from '@components/icons-component/ExercisesIcon';
import TeamsIcon from '@components/icons-component/TeamsIcon';
import UsersIcon from '@components/icons-component/UsersIcon';
import {TFunction} from 'i18next';
import AdminIcon from '@components/icons-component/AdminIcon';
import CoachesIcon from '@components/icons-component/CoachesIcon';
import ContractsIcon from '@components/icons-component/ContractsIcon';
import SportsIcon from '@components/icons-component/SportsIcon';
import LeaderboardIcon from '@components/icons-component/LeaderboardIcon';
import UserProfileIcon from '@components/icons-component/UserProfileIcon';

export const languageKeys = {EN: 'en', FR: 'fr', HI: 'hi'};
export const ADMIN_LIST_ITEMS = [
  {
    label: 'PRIVATE.SIDE_NAV.LEADERBOARD',
    value: PRIVATE_ROUTES.LEADERBOARD,
    Icon: LeaderboardIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.CHALLENGES',
    value: PRIVATE_ROUTES.CHALLENGES,
    Icon: ChallengesIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.TEAMS',
    value: PRIVATE_ROUTES.ORGANIZATIONS,
    Icon: TeamsIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.USERS',
    value: PRIVATE_ROUTES.USERS,
    Icon: UsersIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.CHALLENGES_FEED',
    value: PRIVATE_ROUTES.CHALLENGES_FEED,
    Icon: ChallengesFeedIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.EXERCISES',
    value: PRIVATE_ROUTES.EXERCISES,
    Icon: ExercisesIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.REGIONS',
    value: PRIVATE_ROUTES.REGIONS,
    Icon: RegionsIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.CONTRACTS',
    value: PRIVATE_ROUTES.CONTRACTS,
    Icon: ContractsIcon
  }
  // {
  //   label: 'PRIVATE.SIDE_NAV.FUNDRAISING',
  //   value: PRIVATE_ROUTES.FUNDRAISING,
  //   Icon: FundRaisingIcon
  // }
];
export const CLIENT_LIST_ITEMS = [
  {
    label: 'PRIVATE.SIDE_NAV.ORGANIZATIONS',
    value: PRIVATE_ROUTES.ORGANIZATIONS,
    Icon: TeamsIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.ADMINISTRATORS',
    value: PRIVATE_ROUTES.ADMINISTRATORS,
    Icon: AdminIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.COACHES',
    value: PRIVATE_ROUTES.COACHES,
    Icon: CoachesIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.USERS',
    value: PRIVATE_ROUTES.USERS,
    Icon: UsersIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.SPORTS',
    value: PRIVATE_ROUTES.SPORTS,
    Icon: SportsIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.CHALLENGES',
    value: PRIVATE_ROUTES.CHALLENGES,
    Icon: ChallengesIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.LEADERBOARD',
    value: PRIVATE_ROUTES.LEADERBOARD,
    Icon: LeaderboardIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.CONTRACTS',
    value: PRIVATE_ROUTES.CONTRACTS,
    Icon: ContractsIcon
  },
  {
    label: 'PRIVATE.SIDE_NAV.CLIENT_PROFILE',
    value: PRIVATE_ROUTES.CLIENT_PROFILE_URL,
    Icon: UserProfileIcon
  }
];

export const CONTRACT_LIST_ITEM = [
  {
    label: 'PRIVATE.SIDE_NAV.CONTRACTS',
    value: PRIVATE_ROUTES.CONTRACTS
  },
  {
    label: 'PRIVATE.SIDE_NAV.MY_PROFILE',
    value: PRIVATE_ROUTES.PROFILE
  }
];

export const USER_TYPES = {
  ADMIN: 'admin',
  ATHLETIC_DIRECTOR: 'ATHLETIC_DIRECTOR',
  COACH: 'COACH',
  GENERAL_USER: 'GENERAL_USER',
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
  STUDENT_ATHLETE: 'STUDENT_ATHLETE'
};

export const schoolTypesData = (
  t: TFunction<'translation', undefined>
): {label: string; value: string}[] => {
  return [
    {
      label: t('PRIVATE.HIGH_SCHOOL'),
      value: 'HIGH_SCHOOL'
    },
    {
      label: t('PRIVATE.COLLEGE'),
      value: 'COLLEGE'
    },
    {
      label: t('PRIVATE.PRO_TEAM'),
      value: 'PRO_TEAM'
    },
    {
      label: t('PRIVATE.COMPANY'),
      value: 'COMPANY'
    },
    {
      label: t('PRIVATE.GYM'),
      value: 'GYM'
    }
  ];
};

export const measurementData = (
  t: TFunction<'translation', undefined>
): {label: string; value: string}[] => {
  return [
    {
      value: t('EXERCISES.INCHES'),
      label: 'INCHES'
    },
    {
      value: t('EXERCISES.POUNDS'),
      label: 'POUNDS'
    },
    {
      value: t('EXERCISES.FOOT_INCH'),
      label: 'FOOT_INCH'
    },
    {
      value: t('EXERCISES.REPS'),
      label: 'REPS'
    },
    {
      value: t('EXERCISES.SECONDS'),
      label: 'SECONDS'
    },
    {
      value: t('EXERCISES.HOURS_AND_MINUTES'),
      label: 'HOURS_AND_MINUTES'
    },
    {
      value: t('EXERCISES.MINUTES_AND_SECONDS'),
      label: 'MINUTES_SECONDS'
    }
  ];
};

export const proofForSubmission = (
  t: TFunction<'translation', undefined>
): {label: string; value: string}[] => {
  return [
    {
      value: t('EXERCISE.LINK'),
      label: 'LINK'
    },
    {
      value: t('EXERCISE.VIDEO'),
      label: 'VIDEO'
    }
  ];
};

export const bodyWeightType = (
  t: TFunction<'translation', undefined>
): {label: string; value: string}[] => {
  return [
    {
      value: t('EXERCISE.BODY_WEIGHT'),
      label: 'BODYWEIGHT'
    },
    {
      value: t('EXERCISE.LOWER_BODY'),
      label: 'LOWERBODY'
    },
    {
      value: t('EXERCISE.UPPER_BODY'),
      label: 'UPPERBODY'
    }
  ];
};

export const CONTRACT_TABS = [
  {label: 'All', value: '0'},
  {label: 'Open', value: '1'},
  {label: 'Applied', value: '2'},
  {label: 'Won', value: '3'},
  {label: 'Closed', value: '4'}
];

export const CLIENT_CONTRACT_TABS = [
  {label: 'open', value: '0'},
  {label: 'awarded', value: '1'},
  {label: 'closed', value: '2'}
];

export const EVENT = 'EVENT';

export const CHALLENGE_UPLOAD_SUBMISSION = 'CHALLENGE_UPLOAD_SUBMISSION';
export const CONFIRM_CHALLENGE_VIDEO = 'CONFIRM_CHALLENGE_VIDEO';

export const ORGANIZATION_STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  DENIED: 'DENIED'
};

export const CONTRACT_STATUS = {
  OPEN: 'OPEN',
  AWARDED: 'AWARDED',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  WON: 'WON',
  INVITED: 'INVITED',
  APPLIED: 'APPLIED',
  DRAFT: 'DRAFT',
  ALL: 'ALL'
};

export const PAYMENT_STATUS = {
  INVOICE_SENT: 'INVOICE_SENT',
  PAID: 'PAID'
};
export const CONTRACT_PAYMENT_STATUS = {
  PAID: 'PAID',
  NOT_PAID: 'NOT_PAID',
  PENDING: 'PENDING',
  COLLECT: 'COLLECT'
};

export const USER_TYPE = {
  ORG: 'ORG',
  ATHLETE: 'ATHLETE',
  COACH: 'COACH'
};

export const SUBMISSION_STATUS = {
  INPROGRESS: 'INPROGRESS',
  APPROVED: 'APPROVED',
  DISMISSED: 'DISMISSED'
};

export const PAYMENT_TYPE = {
  FIRST_PAYMENT: 'first_payment',
  SECOND_PAYMENT: 'second_payment'
};

export const WinnerDeterminedBy = [
  {
    label: 'APPLY_CONTRACT.ME',
    value: 'Me'
  },
  {
    label: 'APPLY_CONTRACT.LEADERBOARD',
    value: 'Leaderboard'
  }
];

export const CHALLENGE_STATUS = {
  APPROVED: 'Approved',
  APPROVED_NOT_STARTED: 'Approved-NS'
};

export const ALL_LABEL = 'All';
export const GENERAL_USER = 'General User';
export const MAX_CHAR_LIMIT_FOR_USER_HANDLE =32;
