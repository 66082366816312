import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import authReducer from '../modules/home/state/auth/AuthStateSlice';
import contractsReducer from '../modules/home/state/contracts/ContractStateSlice';

const persistConfig = {
  key: 'counter',
  storage
};

const rootReducer = combineReducers({
  auth: authReducer,
  contract: contractsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;
