import React, {useState} from 'react';
import {loginService} from 'src/services/service-handlers/PublicService';
import {useDispatch} from 'react-redux';
import {login, userLoginType} from '../../../home/state/auth/AuthStateSlice';
import AuthUtils from '@utils//AuthUtils';
import {useNavigate} from 'react-router-dom';
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from '@constants/RouteConstants';
import {Trans, useTranslation} from 'react-i18next';
import './Login.scss';
import {useForm, Controller} from 'react-hook-form';
import {FormControl, FormHelperText, IconButton, InputAdornment} from '@mui/material';
import {validateLoginHookForm} from './LoginValidator';
import {LoginParams} from 'src/services/service-mappers/PublicServiceMapper';
import NextUpPerformanceFullLogo from '@components/icons-component/NextUpPerformanceFullLogo';
import TextFieldComponent from '@components/mui-library-components/TextFieldComponent';
import ButtonComponent from '@components/mui-library-components/ButtonComponent';
import LinkComponent from '@components/mui-library-components/LinkComponent';
import CloseIconComponent from '@components/icons-component/CloseIconComponent';
import Services from 'src/services/Services';
import CircularProgressComponent from '@components/mui-library-components/CircularProgressComponent';
import {ORGANIZATION_STATUS, USER_TYPES} from '@constants/AppConstants';
import {getAllSubscribedOrganizations} from 'src/services/service-handlers/ContractService';
import {OrganizationType} from 'src/services/service-mappers/ContractServiceMapper';
import VisibilityIconComponent from '@components/mui-library-components/VisibilityIcon';
import VisibilityOffIconComponent from '@components/mui-library-components/VisibilityOffIcon';
interface LoginProps {
  pathName?: string;
}
const Login = (props: LoginProps): React.ReactElement => {
  const {t} = useTranslation();
  const {pathName} = props;
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState<boolean>(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const [invalidCredentialsErrorText, setInvalidCredentialsErrorText] =
    useState('');
  const [loader, setLoader] = useState<boolean>(false); // To show the loader in login button
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  type Data = {
    email: string;
    password: string;
  };
  // validation
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<Data>({
    defaultValues: {
      email: '',
      password: ''
    },
    mode: 'all',
    resolver: async values => {
      return {values, errors: validateLoginHookForm(values)};
    }
  });

  const handleFormSubmit = async (values: LoginParams) => {
    try {
      setLoader(true);
      setInvalidCredentialsErrorText('');
      const response = await loginService(values);
      if (response?.token !== null) {
        const token = response?.token;
        Services.initAfterAuth({token});
        if (pathName === PUBLIC_ROUTES.ADMIN) {
          // For admin credentials
          if (response?.user?.type === USER_TYPES.ADMIN) {
            // If the pathname and the login response type match, then navigate to leaderboard tab
            setErrorText(false);
            dispatch(login({token}));
            // dispatch userLoginType
            dispatch(
              userLoginType({
                type: response?.user?.type,
                id: response?.user?.id,
                school: response?.user?.school
              })
            );
            AuthUtils.setAuthToken(response.token, PUBLIC_ROUTES.ADMIN);
            navigate(`/${PRIVATE_ROUTES.ADMIN}`);
          } else {
            //  If the pathname and the login response type do not match, display an error text
            setErrorText(true);
          }
        } else if (pathName === PUBLIC_ROUTES.CLIENT) {
          // For client credentials
          if (response?.user?.type === USER_TYPES.SCHOOL_ADMIN) {
            // If the pathname and the login response type match, then navigate to teams tab
            setErrorText(false);
            dispatch(login({token}));
            // dispatch userLoginType
            dispatch(
              userLoginType({
                type: response?.user?.type,
                id: response?.user?.id,
                school: response?.user?.school
              })
            );
            AuthUtils.setAuthToken(response.token, PUBLIC_ROUTES.CLIENT);
            navigate(`/${PRIVATE_ROUTES.CLIENT}`);
          } else {
            //  If the pathname and the login response type do not match, display an error text
            setErrorText(true);
          }
        } else if (pathName === PUBLIC_ROUTES.CONTRACT) {
          // For Student Athlete Credentials

          const allOrganizations = await getAllSubscribedOrganizations({
            user_id: response?.user?.id
          });

          const allOrgArray: OrganizationType[] = [];
          allOrganizations.rows.map(org => {
            if (org.status === ORGANIZATION_STATUS.APPROVED) {
              allOrgArray.push(org);
            }
          });
          if (
            response?.user?.type === USER_TYPES.STUDENT_ATHLETE ||
            response?.user?.type === USER_TYPES.COACH
          ) {
            if (allOrgArray.length === 0) {
              setIsSuspended(true);
            } else {
              // Either a student athlete or coach can login to the contract portal
              // If the pathname and the login response type match, then navigate to teams tab
              setErrorText(false);
              dispatch(login({token}));
              // dispatch userLoginType
              dispatch(
                userLoginType({
                  type: response?.user?.type,
                  id: response?.user?.id,
                  school: response?.user?.school
                })
              );
              AuthUtils.setAuthToken(response.token, PUBLIC_ROUTES.CONTRACT);
              navigate(`/${PRIVATE_ROUTES.CONTRACT}`);
            }
          } else {
            //  If the pathname and the login response type do not match, display an error text
            setIsSuspended(false);
            setErrorText(true);
          }
        }
      }
    } catch (e) {
      // Handle error
      setInvalidCredentialsErrorText((e as Error).message);
      setErrorText(false);
      setIsSuspended(false);
    } finally {
      setLoader(false);
    }
  };

  const navigateToForgotPasswordPage = () => {
    if (pathName === PUBLIC_ROUTES.ADMIN) {
      navigate(`/${PUBLIC_ROUTES.FORGOT}`);
    } else if (pathName === PUBLIC_ROUTES.CLIENT) {
      navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.FORGOT}`);
    } else if (pathName === PUBLIC_ROUTES.CONTRACT) {
      navigate(`/${PUBLIC_ROUTES.CONTRACT}/${PUBLIC_ROUTES.FORGOT}`);
    }
  };

  const navigateToSignUpform = () => {
    navigate(`/${PUBLIC_ROUTES.SIGNUP}`);
  };

  const handleClickShowPassword = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
    } else {
      setIsPasswordVisible(true);
    }
  };

  return (
    <div className="login-form">
      <div className="next-up-logo">
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <legend className="legend-header">
          {pathName === PUBLIC_ROUTES.ADMIN && t('PRIVATE.ADMIN_LOG_IN')}
          {pathName === PUBLIC_ROUTES.CLIENT && t('PRIVATE.CLIENT_LOG_IN')}
          {pathName === PUBLIC_ROUTES.CONTRACT && t('PRIVATE.ATHLETE_LOG_IN')}
        </legend>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.EMAIL')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-field"
              error={!!errors.email}>
              <Controller
                control={control}
                name="email"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.email ? true : false}
                    className="input-field-styles"
                    type="email"
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              email: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.email && (
                <FormHelperText className="error-text-component">
                  {t(errors.email as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['password-field', 'item-parent-div'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.PASSWORD')}
              </label>
              {pathName !== PUBLIC_ROUTES.CONTRACT && (
                <a
                  className={['link', 'link-position'].join(' ')}
                  onClick={navigateToForgotPasswordPage}>
                  {t('PRIVATE.LOG_IN.FORGOT_PASSWORD')}
                </a>
              )}
            </div>

            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-field"
              error={!!errors.password}>
              <Controller
                control={control}
                name="password"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.password ? true : false}
                    className="input-field-styles"
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <div className='input-props-parent-div'>
                          <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} className='visibility-icon-local-styles'>
                            {isPasswordVisible ? (
                              <VisibilityIconComponent
                               className='visibility-icon'
                               value={value}
                              />
                            ) : (
                              <VisibilityOffIconComponent
                               className='visibility-icon'
                               value={value}
                              />
                            )}
                          </IconButton>
                          </InputAdornment>
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            setIsPasswordVisible(false);
                            reset(formValues => ({
                              ...formValues,
                              password: ''
                            }));
                          }}
                        />
                        </div>
                      )
                    }}
                  />
                )}
              />
              {errors.password && (
                <FormHelperText className="error-text-component">
                  {t(errors.password as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="login-button-parent-div">
            {invalidCredentialsErrorText?.length > 0 && (
              <div className="error-text">{invalidCredentialsErrorText}</div>
            )}{' '}
            {/*  when email or password is invalid */}
            {errorText && (
              <div className="error-text">
                <Trans
                  i18nKey={'PRIVATE.INVALID_USER'}
                  values={{
                    userType: pathName
                  }}
                  components={[<span key={'X'} />]}
                />
              </div>
            )}
            {!errorText && isSuspended && (
              <div className="error-text">{t('PRIVATE.SUSPENDED_ACCOUNT')}</div>
            )}
            <ButtonComponent
              type="submit"
              onClick={handleSubmit(handleFormSubmit)}
              variant="contained"
              disabled={loader}
              className={[
                'login-button-styles',
                'login-button-font-styles'
              ].join(' ')}>
              {loader ? (
                <CircularProgressComponent className="circular-progress-color" />
              ) : (
                t('PRIVATE.USER_LOG_IN')
              )}
            </ButtonComponent>
          </div>
          {pathName === PUBLIC_ROUTES.CONTRACT && (
            <LinkComponent
              className={[
                'contract-forgot-password-link',
                'contract-link-text-local-styles'
              ].join(' ')}
              component="button"
              onClick={navigateToForgotPasswordPage}>
              {t('PRIVATE.LOG_IN.CONTRACT_PORTAL_FORGOT_PASSWORD')}
            </LinkComponent>
          )}
          {pathName === PUBLIC_ROUTES.CONTRACT && (
            <div className="contract-portal-subtitle-div">
              <label className="contract-portal-subtitle">
                {t('PRIVATE.DOWNLOAD_NEXTUP_SUBTITLE')}
              </label>
            </div>
          )}
          {pathName === PUBLIC_ROUTES.CLIENT && (
            <div className={['link-text', 'link-text-local-styles'].join(' ')}>
              <LinkComponent
                className="link-text"
                component="button"
                onClick={navigateToSignUpform}>
                {t('PRIVATE.CREATE_ACCOUNT')}
              </LinkComponent>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
