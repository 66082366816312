import * as React from 'react';
import NextUpFullLogo from '../../assets/images/nextuplogo.svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */
import {memo} from 'react';

const SvgComponent = () => (
  <img
    src={NextUpFullLogo}
    height={'100%'}
    width={'100%'}
    alt={'next-up-full-logo'}
  />
);

const NextUpPerformanceFullLogo = memo(SvgComponent);
export default NextUpPerformanceFullLogo;
