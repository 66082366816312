import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import './ForgotPasswordPage.scss';
import {useForm, Controller} from 'react-hook-form';
import {FormControl, FormHelperText} from '@mui/material';
import {SnackBarConfig} from '@utils//SnackBarConfig';
import {useSnackbar} from 'notistack';
import {forgotPassword} from 'src/services/service-handlers/PublicService';
import {ForgotPasswordParams} from 'src/services/service-mappers/PublicServiceMapper';
import NextUpPerformanceFullLogo from '@components/icons-component/NextUpPerformanceFullLogo';
import TextFieldComponent from '@components/mui-library-components/TextFieldComponent';
import ButtonComponent from '@components/mui-library-components/ButtonComponent';
import CloseIconComponent from '@components/icons-component/CloseIconComponent';
import {validateForgotPassword} from './ValidateForgotPassword';
import CircularProgressComponent from '@components/mui-library-components/CircularProgressComponent';
interface forgotPasswordProps {
  pathName?: string;
}
const ForgotPassword = (props: forgotPasswordProps): React.ReactElement => {
  const {t} = useTranslation();
  const {pathName} = props;
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false); // To show the loader in submit button

  const navigateToAdminLogin = () => {
    if (pathName === PUBLIC_ROUTES.ADMIN) {
      navigate(`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`);
    } else if (pathName === PUBLIC_ROUTES.CLIENT) {
      navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
    } else if (pathName === PUBLIC_ROUTES.CONTRACT) {
      navigate(`/${PUBLIC_ROUTES.CONTRACT}/${PUBLIC_ROUTES.LOGIN}`);
    }
  };

  type Data = {
    email: string;
  };
  const {
    control,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<Data>({
    mode: 'all',
    resolver: async values => {
      return {values, errors: validateForgotPassword(values)};
    }
  });

  const handleFormSubmit = async (values: ForgotPasswordParams) => {
    try {
      // making loader state to false after 3 seconds
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, 3000);
      await forgotPassword(values);
      enqueueSnackbar(t('EMAIL.SENT'), SnackBarConfig('s'));
      if (pathName === PUBLIC_ROUTES.ADMIN) {
        setLoader(false);
        navigate(`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`);
      } else if (pathName === PUBLIC_ROUTES.CLIENT) {
        setLoader(false);
        navigate(`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`);
      } else if (pathName === PUBLIC_ROUTES.CONTRACT) {
        setLoader(false);
        navigate(`/${PUBLIC_ROUTES.CONTRACT}/${PUBLIC_ROUTES.LOGIN}`);
      }
    } catch (e) {
      setLoader(false);
      enqueueSnackbar((e as Error).message, SnackBarConfig('e'));
    }
  };

  return (
    <div className="forgot-password-form">
      <div className="next-up-logo">
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <div className="legend-header">{t('PRIVATE.FORGOT_PASSWORD')}</div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className={'input-label'}>
                {t('PRIVATE.LOG_IN.EMAIL')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="login-input-field"
              error={!!errors.email}>
              <Controller
                control={control}
                name="email"
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.email ? true : false}
                    type="email"
                    className={'input-field-styles'}
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              email: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.email && (
                <FormHelperText className="error-text-component">
                  {t(errors.email as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="forgot-buttons-parent-div">
            <ButtonComponent
              type="button"
              onClick={navigateToAdminLogin}
              className={[
                'back-button-styles',
                'login-button-font-styles'
              ].join(' ')}
              variant="contained">
              {t('PRIVATE.BACK')}
            </ButtonComponent>
            <ButtonComponent
              type="submit"
              variant="contained"
              disabled={loader}
              className={[
                'submit-button-styles',
                'login-button-font-styles'
              ].join(' ')}
              onClick={handleSubmit(handleFormSubmit)}>
              {loader ? (
                <CircularProgressComponent className="circular-progress-color" />
              ) : (
                t('PRIVATE.SUBMIT')
              )}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
