import * as CryptoJS from 'crypto-js';

const isEncryptLocalStorage = process.env.REACT_APP_ENCRYPT_URL_KEY || '';
const key = process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE_KEY || '';

export const encrypt = (value: string): string => {
  return isEncryptLocalStorage
    ? CryptoJS.AES.encrypt(value, key).toString()
    : value;
};

export const decrypt = (value: string | null): string => {
  try {
    return value
      ? isEncryptLocalStorage
        ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
        : value
      : '';
  } catch (e) {
    return value || '';
  }
};
