import {emailValidator} from '@utils//Validate';
import {FieldValues} from 'react-hook-form';

export const validateLoginHookForm = (values: FieldValues): FieldValues => {
  let errors: FieldValues = {
    email: '',
    password: ''
  };
  if (!values.email || !values.email.replace(/\s/g, '').length) {
    errors.email = 'VALIDATORS.EMAIL_REQUIRED';
  } else if (!emailValidator(String(values.email).toLowerCase())) {
    errors.email = 'VALIDATORS.EMAIL_NOT_VALID';
  }
  if (!values.password || !values.password.replace(/\s/g, '').length) {
    errors.password = 'VALIDATORS.PASSWORD_REQUIRED';
  }
  if (!errors.email && !errors.password) {
    errors = {};
  }
  return errors;
};
