import {FieldValues} from 'react-hook-form';

export const ResetPasswordValidator = (values: FieldValues): FieldValues => {
  let errors: FieldValues = {
    password: '',
    confirm: ''
  };
  if (!values.password || !values.password.replace(/\s/g, '').length) {
    errors.password = 'VALIDATORS.PASSWORD_REQUIRED';
  }
  if (values.password !== values.confirm) {
    errors.confirm = 'VALIDATORS.PASSWORD_MATCH';
  }

  if (!errors.password && !errors.confirm) {
    errors = {};
  }
  return errors;
};
