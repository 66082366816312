/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="23.000000pt" height="23.000000pt" viewBox="0 0 600.000000 600.000000"
 style={{position: 'relative',right: '3px'}}
 >
<title>Profile</title>
<g transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)">
<path
fill={isActive ? '#E33A34' : '#3c4968'}
d="M2795 5794 c-546 -46 -1049 -236 -1484 -562 -153 -114 -429 -390
-543 -543 -296 -395 -470 -819 -544 -1324 -25 -168 -25 -562 0 -730 86 -591
335 -1124 710 -1521 l82 -88 38 115 c139 431 447 829 836 1082 254 164 567
277 868 311 68 8 126 16 128 19 3 2 -23 7 -57 11 -86 10 -219 50 -317 96 -462
217 -728 718 -647 1220 69 425 364 773 771 909 601 202 1252 -123 1453 -725
185 -552 -74 -1157 -601 -1404 -98 -46 -231 -86 -317 -96 -34 -4 -60 -9 -57
-11 2 -3 60 -11 128 -19 600 -69 1147 -411 1482 -926 78 -121 178 -330 222
-467 l38 -115 82 88 c320 339 566 813 669 1291 48 221 59 335 59 595 0 182 -5
276 -18 365 -74 507 -244 921 -545 1325 -112 151 -388 428 -542 542 -389 291
-806 465 -1294 539 -127 19 -480 33 -600 23z" />
</g>
</svg>
);

const UserProfileIcon = memo(SvgComponent);
export default UserProfileIcon;
