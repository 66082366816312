import {USER_TYPES} from '@constants/AppConstants';
import {PRIVATE_ROUTES} from '@constants/RouteConstants';
import Logout from '@modules/public/main/logout/Logout';
import {LinearProgress} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {RootState} from 'src/store/Store';
const AdminHome = React.lazy(
  () => import('../modules/home/main/admin-home/AdminHome')
);
const ClientHome = React.lazy(
  () => import('../modules/home/main/client-home/ClientHome')
);
const ContractHome = React.lazy(
  () => import('../modules/home/main/contract-home/ContractHome')
);

const HomeRoutes = (): React.ReactElement => {
  const loggedInUser = useSelector((state: RootState) => {
    return state.auth.userToken && state.auth.userType;
  });

  // ! FIXME: These routes should be protected via user type
  return (
    <Router>
      <React.Suspense fallback={<LinearProgress />}>
        <Routes>
          {loggedInUser === USER_TYPES.ADMIN && (
            <Route path={`*`} element={<AdminHome />} />
          )}

          {(loggedInUser === USER_TYPES.SCHOOL_ADMIN) && (
            <Route path={`*`} element={<ClientHome />} />
          )}

          {(loggedInUser === USER_TYPES.STUDENT_ATHLETE || loggedInUser === USER_TYPES.COACH) && (
            <Route path={`*`} element={<ContractHome />} />
          )}
          <Route path={`/${PRIVATE_ROUTES.LOGOUT}`} element={<Logout />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
};

export default HomeRoutes;
