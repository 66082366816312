/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60">
    <title>Exercises</title>
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M9,19H6a4.4,4.4,0,0,0-4,4v6H1a1,1,0,0,0,0,2H2v6a4.4,4.4,0,0,0,4,4H9a1,1,0,0,0,1-1V20A1,1,0,0,0,9,19Z"
    />
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M44,13H40a3.64,3.64,0,0,0-4,4V29H24V17a3.64,3.64,0,0,0-4-4H16a3.64,3.64,0,0,0-4,4V43a3.64,3.64,0,0,0,4,4h4a3.64,3.64,0,0,0,4-4V31H36V43a3.64,3.64,0,0,0,4,4h4a3.64,3.64,0,0,0,4-4V17A3.64,3.64,0,0,0,44,13Z"
    />
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M59,29H58V23a4.4,4.4,0,0,0-4-4H51a1,1,0,0,0-1,1V40a1,1,0,0,0,1,1h3a4.4,4.4,0,0,0,4-4V31h1a1,1,0,0,0,0-2Z"
    />
  </svg>
);

const ExercisesIcon = memo(SvgComponent);
export default ExercisesIcon;
