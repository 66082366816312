import {Link} from '@mui/material';
import React, {ElementType} from 'react';
interface LinkComponentParams {
  component: ElementType;
  children: string | JSX.Element;
  className?: string;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
}
const LinkComponent = (props: LinkComponentParams): React.ReactElement => {
  const {component, children, onClick, className} = props;
  return (
    <Link component={component} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

export default LinkComponent;
