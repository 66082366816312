import {Button} from '@mui/material';
import React from 'react';
interface ButtonComponentParams {
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  variant: 'text' | 'outlined' | 'contained';
  children: string | JSX.Element;
  className?: string;
  disabled?: boolean | undefined;
}
const ButtonComponent = (props: ButtonComponentParams): React.ReactElement => {
  const {type, variant, children, onClick, className, disabled} = props;
  return (
    <Button
      type={type}
      variant={variant}
      onClick={onClick}
      className={className}
      disabled={disabled}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
