import {emailValidator} from '@utils//Validate';
import {FieldValues} from 'react-hook-form';

export const validateForgotPassword = (values: FieldValues): FieldValues => {
  let errors: FieldValues = {
    email: ''
  };
  if (!values.email || !values.email.replace(/\s/g, '').length) {
    errors.email = 'VALIDATORS.EMAIL_REQUIRED';
  } else if (!emailValidator(String(values.email).toLowerCase())) {
    errors.email = 'VALIDATORS.EMAIL_NOT_VALID';
  }

  if (!errors.email) {
    errors = {};
  }
  return errors;
};
