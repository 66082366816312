import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import Palette from './palette';
import overrides from './overrides';
import {getTypography} from './typography';

let theme = createTheme({
  typography: palette => {
    return getTypography(palette);
  },
  components: {
    ...overrides
  },
  palette: Palette
});
theme = responsiveFontSizes(theme);

export default theme;
