import {Subject, Observable} from 'rxjs';

type PAYMENT_DATA = {
  type: 'RELOAD_PAYMENTS';
  data: null;
};
const subject = new Subject<PAYMENT_DATA>();
interface DefaultEventType<A, D> {
  action: A;
  data: D;
}
declare type EventActionAppVersionUpdated = DefaultEventType<
  'APP_VERSION_UPDATED',
  null
>;

declare type OrganizationTypeChanged = DefaultEventType<
  'ORG_TYPE_CHANGED',
  null
>;

declare type EventType = EventActionAppVersionUpdated | OrganizationTypeChanged;

const event = new Subject<EventType>();

export const CommunicationService = {
  getMessage: (): Observable<PAYMENT_DATA> => subject.asObservable(),
  sendMessage: (data: PAYMENT_DATA): void => subject.next(data),

  /**
   * @description This is generic event subscription method to capture the event.
   * Use this in the component based on a ref and cancel the subscription if the component is unmounted
   */
  getEvent: (): Observable<EventType> => event.asObservable(),

  /**
   * @description This is generic event emitter method to emit an event.
   * Use this in the component where you want to emit event
   */
  sendEvent: (data: EventType): void => event.next(data)
};
