import React from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';

interface VisibilityIconComponentProps {
    className?: string;
    value?: string | undefined;
}

const VisibilityIconComponent = (props: VisibilityIconComponentProps):React.ReactElement => {

    const {className,value} = props;

    return (
        <VisibilityIcon
        className={className}
        style={{
            visibility: value ? 'visible' : 'hidden'
          }}
        />
    )
}

export default VisibilityIconComponent;