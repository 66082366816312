import {createSlice} from '@reduxjs/toolkit';

import {AuthenticationStateType} from './AuthStateTypes';
import Services from 'src/services/Services';

const initialState: AuthenticationStateType = {
  isAuthenticated: false,
  userToken: {
    token: null
  },
  userType: '',
  userId: -1,
  schoolId: -1,
  clientRegistration: {
    isValid: false,
    confirm: '',
    email: '',
    password: '',
    type: '',
    username: '',
    userHandle: ''
  },
  orgData: {
    organizationName: '',
    organizationId: null,
    state: '',
    region: ''
  },
  organizationList: []
};

const authenticationSlice = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    login: (state, param) => {
      state.isAuthenticated = true;
      state.userToken = param?.payload?.token;
      Services.initAfterAuth(param.payload);
    },
    userLoginType: (state, param) => {
      const user = param?.payload;
      state.userType = user.type;
      state.userId = user.id;
      state.schoolId = user.school;
    },
    organizatonType: (state, param) => {
      const organization = param?.payload;
      state.orgData = organization;
    },
    clientRegistrationParams: (state, param) => {
      const registrationParams = param?.payload;
      state.clientRegistration = registrationParams;
    },
    organizationList: (state, param) => {
      const organization = param?.payload;
      state.organizationList = organization;
    },
    logout: (state, param) => {
      state.isAuthenticated = false;
      state.userToken = param?.payload;
    }
  }
});

export const {
  login,
  userLoginType,
  clientRegistrationParams,
  organizatonType,
  organizationList,
  logout
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
