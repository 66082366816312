import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
interface CloseIconComponents {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  value?: string | undefined;
}
const CloseIconComponent = (props: CloseIconComponents): React.ReactElement => {
  const {className, onClick, value} = props;
  return (
    <CloseIcon
      className={className}
      onClick={onClick}
      style={{
        visibility: value ? 'visible' : 'hidden'
      }}
    />
  );
};

export default CloseIconComponent;
