/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <title>Coaches</title>
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M10.986036,14.7995873 C9.47150154,15.3473948 7.80133368,14.5578747 7.25352618,13.0487806 C6.56423137,11.1213631 7.9948402,9.14437532 9.9924294,9.14437532 C11.1846768,9.14437532 12.3071714,9.88014155 12.7368428,11.067013 C13.2846503,12.5814777 12.4951946,14.2571558 10.986036,14.7995873 Z"
      id="Path"
    />
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M25.7496903,5.03050761 L24.0632604,0.352709994 C23.966628,0.0895580991 23.6819724,-0.055393219 23.4081975,0.0198053121 L16.0665556,2.09283472 C15.2932277,2.31304365 14.809797,3.07024914 14.9065637,3.84357704 L12.4037985,4.52564845 C12.2642876,4.22492951 12.0386383,3.97784095 11.7432308,3.81138593 C11.3673187,3.60192886 10.9322714,3.54823403 10.5133519,3.66643461 C8.82168032,4.17661062 7.61330513,4.37532121 6.32980122,5.0143852 C3.62301929,6.42684154 1.90971512,9.35377331 2.07622922,12.4848453 C1.77051567,12.643368 0.74946548,12.7424977 0.21803818,13.8704918 C-0.0397377871,14.4236108 -0.071993349,15.0412355 0.13753354,15.6159227 C0.568918114,16.8240454 1.92102014,17.4061117 3.05908401,16.985377 L3.62301929,16.7812958 C5.66385759,19.5363913 9.29422484,20.7501424 12.69383,19.5202689 C16.4585955,18.1615664 18.6014421,14.1765921 17.7261058,10.3151298 L25.5187886,5.67502809 C25.7389438,5.54612399 25.8355762,5.27222561 25.7496903,5.03050761 Z M2.69392914,15.975707 C2.08161052,16.1958515 1.36741781,15.8575709 1.14713907,15.2506926 C1.0397548,14.9445977 1.05050665,14.6169401 1.19014649,14.3269086 C1.47429189,13.7259379 2.04199193,13.664531 2.20512258,13.5804603 C2.30969268,14.1549702 2.58905292,15.0495975 3.02683382,15.8521949 L2.69392914,15.975707 Z M11.3511963,15.8091875 C9.30636767,16.5483318 6.99753324,15.5021259 6.24379175,13.4139408 C5.49827796,11.3635752 6.5464602,9.06036907 8.63916733,8.30653629 C10.7121269,7.56008802 12.9999893,8.63419931 13.746443,10.701783 C14.4930255,12.7748768 13.4189142,15.0627392 11.3511963,15.8091875 Z"
      id="Shape"
    />
  </svg>
);

const CoachesIcon = memo(SvgComponent);
export default CoachesIcon;
