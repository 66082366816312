/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <title>Challenges</title>
    <g id="GJ0On0.tif">
      <path
        fill={isActive ? '#E33A34' : '#3c4968'}
        d="M8.808,10.066 L8.80764315,19.9998079 C3.64852227,17.7830271 0.755886973,14.472081 0.12973725,10.0669693 L8.808,10.066 Z M11.3245933,10.0663052 L11.3257304,19.0846855 C10.6420183,19.4584543 9.90763782,19.8077626 9.12258909,20.1326104 L9.437,19.999 L9.437,10.066 L11.3245933,10.0663052 Z M13.2120256,10.0663052 L13.2131871,17.8984625 C12.8169499,18.1854969 12.3973118,18.4612343 11.9542728,18.7256748 L11.9537374,10.0663052 L13.2120256,10.0663052 Z M15.0994578,10.0663052 L15.1006016,16.2646542 C14.7163235,16.6659526 14.2968253,17.0502482 13.8421071,17.417541 L13.8411696,10.0663052 L15.0994578,10.0663052 Z M18.1154409,10.0669693 C17.821042,12.1381358 17.0256067,13.9674228 15.7291353,15.5548301 L15.7286019,10.0663052 Z M13.8411696,2.5165763 C15.2162823,2.5165763 16.4441951,2.06640672 17.2518167,1.36113282 C17.8697442,2.06471994 18.2451782,2.98851632 18.2451782,4 L18.2451782,8.17887297 C18.2451782,8.60768879 18.2263705,9.02742505 18.1887553,9.43808178 L9.437,9.437 L9.43716112,0 L9.52549562,0.000838187204 C9.93399824,1.43627854 11.710962,2.5165763 13.8411696,2.5165763 Z M8.80801705,0 L8.808,9.437 L6.92058482,9.43716112 L6.92120175,1.95242578 C7.83154457,1.49870059 8.49066637,0.805579826 8.71968255,0.000838187204 L8.80801705,0 Z M6.29157375,2.21380898 L6.29144075,9.43716112 L5.0331526,9.43716112 L5.03387975,2.48464531 C5.47793151,2.43922226 5.900607,2.34648337 6.29157375,2.21380898 Z M4.40400852,2.5165763 L4.40400852,9.43716112 L3.14572037,9.43716112 L3.14499651,2.38615419 C3.54390367,2.47100822 3.966506,2.5165763 4.40400852,2.5165763 Z M2.5164433,2.21380898 L2.5165763,9.43716112 L0.0564228849,9.43808178 C0.0188076283,9.02742505 0,8.60768879 0,8.17887297 L0,4 C-5.30279034e-16,2.98900496 0.375071311,2.06561251 0.993656143,1.36138046 C1.40511532,1.72043899 1.92442657,2.01290838 2.5164433,2.21380898 Z"
      />
    </g>
  </svg>
);

const ChallengesIcon = memo(SvgComponent);
export default ChallengesIcon;
