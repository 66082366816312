/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60">
    <title>Regions</title>
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M54.71,10.29A1,1,0,0,0,54,10l-23,.08V3a1,1,0,0,0-1-1H12a1,1,0,0,0-1,1V35a1,1,0,0,0,1,1h9v5a1,1,0,0,0,1,1H54a1,1,0,0,0,1-1V11A1,1,0,0,0,54.71,10.29ZM23,38.53V36h5.16A27.41,27.41,0,0,0,23,38.53Z"
    />
    <path
      fill={isActive ? '#E33A34' : '#3c4968'}
      d="M7,0A2,2,0,0,0,5,2V58a2,2,0,0,0,4,0V2A2,2,0,0,0,7,0Z"
    />
  </svg>
);

const RegionsIcon = memo(SvgComponent);
export default RegionsIcon;
