import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/Style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@emotion/react';
import {Provider} from 'react-redux';

import './i18n';
import theme from './styles/theme';
import {SnackbarProvider} from 'notistack';
import store from './store/Store';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
