import ServiceRequest from '../ServiceRequest';
import {baseEnvironment, config} from '../../config/environment/environment';
import {
  ApplyContractParams,
  CreateContractParams,
  GetContractListParams,
  GetContractsListService,
  ContractResponseParams,
  GetUsersParams,
  SearchUserParams,
  GetExternalLinksService,
  GetContractInvitees,
  GetContractsSubmissionListDataService,
  UploadVideoService,
  AwardContractResponse,
  CreateorEditContractResponse,
  InvitedContracts,
  UserProfileDetails,
  FollowUsersCountType,
  OrganizationType,
  ChallengeDataResponse,
  GetContractConversationList,
  PostContractConversationParams,
  PostContractConversationResponse,
  GetContractSubmissionListResponse,
  submitVideoResponseData,
  ApproveContractChallengeByOrgResponse,
  GetContractChallengeSubmissionListResponse,
  FetchAdminContractsResponseService,
  FetchContractsPaymentService,
  AllContractsType,
  MarkContractSubmissionActivityResponse,
  MarkUserResponse,
  ChangePaymentApiParams
} from '../service-mappers/ContractServiceMapper';
import {CancelTokenSource} from 'axios';

const createContractUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT}`;
const getExternalLinksUrl = `${config.BASE_URL}/v/1/${baseEnvironment.CONTRACTS.GET_EXTERNAL_LINKS}`;
const applyContractUrl = `${config.BASE_URL}/v/2/${baseEnvironment.SUBMIT_CONTRACT.CONTRACT_SUBMISSION}`;
const contractSubmissionUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_SUBMISSION}`;
const searchUsersUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.USERS}/${baseEnvironment.CONTRACTS.LIST}`;
const contractInviteUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_INVITE}`;
const awardContractUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT}/${baseEnvironment.CONTRACTS.AWARD}`;
const contractConversationUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_CONVERSATION}`;
const dismissContractUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_SUBMISSION}/${baseEnvironment.CONTRACTS.DISMISS}`;
const getContractSubmissionUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_SUBMISSION}`;
const approveContractChallengeByOrgUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_CHALLENGE}/${baseEnvironment.CONTRACTS.ORG}/${baseEnvironment.CONTRACTS.APPROVE}`;
const declineContractChallengeByOrgUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_CHALLENGE}/${baseEnvironment.CONTRACTS.DECLINE}`;
const getContractChallengeSubmissionUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_CHALLENGE}/${baseEnvironment.CONTRACTS.SUBMISSION}`;
const fetchAdminContractsUrl = `${config.BASE_URL}/v/1/${baseEnvironment.CONTRACTS.CHALLENGE}/${baseEnvironment.CONTRACTS.FIND_ALL}`;
const getContractChallengeSubmissionListUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_CHALLENGE}/${baseEnvironment.CONTRACTS.SUBMISSION}/${baseEnvironment.CONTRACTS.LIST}`;
const approveContractChallengeByNextupUrl = `${config.BASE_URL}/v/2/${baseEnvironment.CONTRACTS.CONTRACT_CHALLENGE}/${baseEnvironment.CONTRACTS.NEXTUP}/${baseEnvironment.CONTRACTS.APPROVE}`;
const changePaymentStatusUrl = `${config.BASE_URL}/v/2/contract_payment`;
const CompleteContractSubmissionUrl = `${config.BASE_URL}/v/2/contract_submission/complete`;
const markContractSubmissionActivityUrl = `${config.BASE_URL}/v/2/contract_submission/markactivity`;

export const uploadVideoOnServer = async (body: {
  fileUri: string;
  name: string;
  type: string;
  challenge_type: string;
}): Promise<UploadVideoService> => {
  return await ServiceRequest.postDocVideo(
    `${config.BASE_URL}/v/2/file/${body.challenge_type}`,
    body
  );
};

export const addContract = async (
  values: CreateContractParams
): Promise<CreateorEditContractResponse> => {
  return ServiceRequest.post(createContractUrl, {
    payload: values
  });
};

export const editContract = async (
  values: CreateContractParams
): Promise<CreateorEditContractResponse> => {
  const url = createContractUrl + `/${values.id}`;
  return ServiceRequest.put(url, {
    payload: values
  });
};

export const getAllContracts = async (
  payload: {
    pageNumber: number;
    noOfItems: number;
    selectedTab: string;
  },
  cancelToken?: CancelTokenSource
): Promise<AllContractsType> => {
  return ServiceRequest.get(
    `${config.BASE_URL}/v/2/contract/${payload.pageNumber}/${payload.noOfItems}/${payload.selectedTab}`,
    {payload: null},
    cancelToken
  );
};

export const getContractsList = async (
  values: GetContractListParams,
  cancelToken?: CancelTokenSource
): Promise<GetContractsListService> => {
  let url =
    `${createContractUrl}` +
    `/${values.page_number}` +
    `/${values.no_of_items}` +
    `?org_id=${values.org_id}&searchKey=${values.search_key}`;
  if (values.status_name !== '') {
    url = url + `&status=${values.status_name}`;
  }
  if (values.order_by) {
    url = url + `&orderBy=${values.order_by}`;
  }
  if (values.order_con) {
    url = url + `&orderCon=${values.order_con}`;
  }
  return ServiceRequest.get(url,
    {payload: null},
    cancelToken
    );
};

export const getExternalLinks = async (values: {
  id: number;
}): Promise<GetExternalLinksService[]> => {
  const url = getExternalLinksUrl + `/${values.id}`;
  return ServiceRequest.get(url);
};

export const applyContract = async (
  payload: ApplyContractParams
): Promise<{msg: string}> => {
  const url = applyContractUrl;
  return ServiceRequest.post(url, {payload: payload});
};

export const editContractSubmission = async (payload: {
  data: ApplyContractParams;
  submissionId: number;
}): Promise<{msg: string}> => {
  const url = applyContractUrl + `/${payload.submissionId}`;
  return ServiceRequest.put(url, {payload: payload.data});
};

export const getAllSubmissionForContract = (payload: {
  contractId: number;
}): Promise<{data: ContractResponseParams[]}> => {
  const url = `${config.BASE_URL}/v/2/${baseEnvironment.SUBMIT_CONTRACT.CONTRACT_SUBMISSION}?contract_id=${payload.contractId} `;
  return ServiceRequest.get(url);
};

export const getUserResponseForContract = (payload: {
  contractId: number;
  userId: number;
}): Promise<{data: ContractResponseParams[]}> => {
  const url = `${config.BASE_URL}/v/2/${baseEnvironment.SUBMIT_CONTRACT.CONTRACT_SUBMISSION}?contract_id=${payload.contractId}&user_id=${payload.userId}`;
  return ServiceRequest.get(url);
};

export const getContractsSubmissionList = async (
  values: GetContractListParams
): Promise<GetContractsSubmissionListDataService> => {
  let url =
    `${contractSubmissionUrl}` +
    `/${values.page_number}` +
    `/${values.no_of_items}` +
    `?user_id=${values.user_id}&searchKey=${values.search_key}`;
  if (values.status_name !== '') {
    url = url + `&status=${values.status_name}`;
  }
  return ServiceRequest.get(url);
};

export const getInvitedContracts = async (payload: {
  user_id: number;
  /* eslint-disable @typescript-eslint/no-explicit-any */
}): Promise<{data: InvitedContracts[]}> => {
  const url = `${config.BASE_URL}/v/2/contract_invite?user_id=${payload.user_id}`;
  return ServiceRequest.get(url);
};

export const searchUsers = async (
  values: GetUsersParams
): Promise<SearchUserParams[]> => {
  const url =
    `${searchUsersUrl}` +
    `?searchKey=${values.searchKey}&noOfRecords=${values.noOfRecords}`;
  return ServiceRequest.get(url);
};

export const getContractInvitees = async (values: {
  id: number;
}): Promise<GetContractInvitees> => {
  const url = `${contractInviteUrl}/${values.id}`;
  return ServiceRequest.get(url);
};

export const awardContract = async (
  id: number
): Promise<AwardContractResponse> => {
  const url = awardContractUrl + `/${id}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const getContractConversationList = async (values: {
  id: number;
}): Promise<GetContractConversationList> => {
  const url =
    `${contractConversationUrl}` + `?contract_submission_id=${values.id}`;
  return ServiceRequest.get(url);
};

export const postContractConversation = async (
  payload: PostContractConversationParams
): Promise<PostContractConversationResponse> => {
  return ServiceRequest.post(contractConversationUrl, {payload: payload});
};

export const dismissContract = async (
  id: number
): Promise<AwardContractResponse> => {
  const url = dismissContractUrl + `/${id}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const getContractSubmissionById = async (
  id: number
): Promise<GetContractSubmissionListResponse> => {
  const url = `${getContractSubmissionUrl}` + `?id=${id}`;
  return ServiceRequest.get(url);
};

export const getContractChallengeSubmissionById = async (payload: {
  user_id?: number;
  contract_id: number;
}): Promise<GetContractChallengeSubmissionListResponse> => {
  let url =
    `${getContractChallengeSubmissionUrl}` +
    `?contract_id=${payload.contract_id}`;
  if (payload.user_id) {
    url = url + `&user_id=${payload.user_id}`;
  }
  return ServiceRequest.get(url);
};

export const getUserProfileData = async (values: {
  id?: number;
}): Promise<{rows: UserProfileDetails[]; totalUsers: number}> => {
  const url = `${config.BASE_URL}/v/1/user`;
  return ServiceRequest.get(url, {payload: values});
};

export const getFollowCount = async (payload: {
  user_id?: number;
  following_id?: number;
}): Promise<FollowUsersCountType> => {
  return await ServiceRequest.get(
    `${config.BASE_URL}/v/2/user_following/summary?user_id=${payload.user_id}&following_id=${payload.following_id}`
  );
};

export const getAllSubscribedOrganizations = async (payload?: {
  user_id?: number;
}): Promise<{
  count: number;
  rows: OrganizationType[] | [];
}> => {
  return await ServiceRequest.get(`${config.BASE_URL}/v/2/org/all`, {
    payload: payload
  });
};

export const getChallengeData = async (payload: {
  number_of_items: number;
  tab_name: string;
  page_count: number;
  school_id: number;
  userId?: number;
}): Promise<{rows: ChallengeDataResponse[]}> => {
  return await ServiceRequest.get(
    // eslint-disable-next-line
    `${config.BASE_URL}/v/1/challenge/findAll/${payload.tab_name}/${payload.page_count}/${payload.number_of_items}/${payload.school_id}?user_id=${payload.userId}`
  );
};

export const getLikesOnChallenge = async (payload: {
  challenge_id: number;
}): Promise<[{challenge_id: number; likes_Count: number}]> => {
  return await ServiceRequest.get(
    `${config.BASE_URL}/v/2/challenge/likes/count/${payload.challenge_id}`
  );
};

export const submittedChallengeVideos = async (payload: {
  page_count?: number;
  challenge_id: number | undefined;
  page_size?: number;
}): Promise<submitVideoResponseData> => {
  return await ServiceRequest.get(
    `${config.BASE_URL}/v/1/challenge/response/${payload.challenge_id}/${
      payload.page_count ? payload.page_count : 0
    }/${payload.page_size ? payload.page_size : 3}`
  );
};

export const approveContractChallengeByOrg = async (
  id: number
): Promise<ApproveContractChallengeByOrgResponse> => {
  const url = approveContractChallengeByOrgUrl + `/${id}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const declineContractChallengeByOrg = async (
  id: number
): Promise<ApproveContractChallengeByOrgResponse> => {
  const url = declineContractChallengeByOrgUrl + `/${id}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const fetchAdminContracts = async (payload: {
  status: string;
  count: number;
  page: number;
}): Promise<any> => {
  const url =
    fetchAdminContractsUrl +
    `/${payload.count}/${payload.count}/${payload.page}`;
  return await ServiceRequest.get(url);
};

export const getContractChallengeSubmissionList = async (payload: {
  status?: string;
  page: number;
  count: number;
}): Promise<FetchAdminContractsResponseService> => {
  let url =
    getContractChallengeSubmissionListUrl + `/${payload.page}/${payload.count}`;
  if (payload.status) {
    url = url + `?status=${payload.status}`;
  }
  return await ServiceRequest.get(url);
};

export const getContractPaymentDatabyId = async (
  contractId: number
): Promise<FetchContractsPaymentService> => {
  const url = `${config.BASE_URL}/v/2/contract_payment?contract_id=${contractId}`;
  return ServiceRequest.get(url);
};

export const approveContractChallengeByNextup = async (
  id: number
): Promise<ApproveContractChallengeByOrgResponse> => {
  const url = approveContractChallengeByNextupUrl + `/${id}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const ChangePaymentStatus = async (
  id: number,
  status: string,
  params: ChangePaymentApiParams,
): Promise<AwardContractResponse> => {
  const url = changePaymentStatusUrl + `/${id}/${status}`
  + `?resend_payment_invoice=${params.resendInvoice}&payment_type=${params.paymentType}`;

  return await ServiceRequest.put(url, {
    payload:
     {winnersCount: params.winnersCount,
      suggestedPrize: params.suggestedPrize,
      athleteName: params.athleteName}
  });
};

export const CompleteContractSubmission = async (id: number): Promise<any> => {
  const url = CompleteContractSubmissionUrl + `/${id}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const markContractSubmissionActivity = async (
  id: number,
  type: string
): Promise<MarkContractSubmissionActivityResponse> => {
  const url = markContractSubmissionActivityUrl + `/${id}/${type}`;
  return await ServiceRequest.put(url, {
    payload: {}
  });
};

export const findUser = async (id: number): Promise<MarkUserResponse[]> => {
  return ServiceRequest.get(`${config.BASE_URL}/v/1/user/find/${id}`);
};
