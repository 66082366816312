import React, {ReactElement, useState, useEffect} from 'react';
import AuthUtils from './utils/AuthUtils';
import Services from './services/Services';
import Home from '@modules/home/container/Home';
import PublicHome from '@modules/home/container/PublicHome';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from './store/Store';
import {login} from '@modules/home/state/auth/AuthStateSlice';
import axios from 'axios';
import Storage from './utils/Storage';
import {CommunicationService} from './service/CommunicationService';
import {PRIVATE_ROUTES} from '@constants/RouteConstants';

const AppInitializer = (): ReactElement => {
  const [isAppReady, setAppReady] = useState(false);
  const token = AuthUtils.getAuthToken();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const attachAppListeners = (): void => {
    // Any app listeners to be added
  };
  const removeAppListeners = (): void => {
    // App app listeners to be removed
  };

  const plugInterceptors = () => {
    // const {history} = this.props;
    // Request interceptor
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger

        const apiVersion = new String(response.headers['x-version']);
        const currentVersion = Storage.getItem(Storage.KEYS.APP_VERSION);
        const portal = AuthUtils.resolvePortal();
        if (apiVersion) {
          // if server version newer
          Storage.setItem(Storage.KEYS.APP_VERSION, apiVersion.toString()); // set version update item so can refresh app later
          if (currentVersion !== apiVersion.toString()) {
            /**
             * Check if there is already a current version or the user is logged in
             */
            if (currentVersion || portal) {
              // Trigger an event for version update
              CommunicationService.sendEvent({
                action: 'APP_VERSION_UPDATED',
                data: null
              });
            }
            if (!portal) {
              window.location.reload();
            }
          }
        }

        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        // Logging out for unauthorised access
        if (token && error?.response?.status === 401) {
          window.location.reload();
          window.location.href = `/${PRIVATE_ROUTES.LOGOUT}`;
        }
        return Promise.reject(error);
      }
    );
  };
  useEffect(() => {
    const onMount = async () => {
      if (token) {
        Services.initAfterAuth({token});
        dispatch(login({token}));
        AuthUtils.clearSelectedLoginType();
        attachAppListeners();
      }
      plugInterceptors();
      setTimeout(() => {
        setAppReady(true);
      }, 100);
    };
    onMount();
    return () => {
      removeAppListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRoutes = (): React.ReactNode => {
    if (!isAppReady) {
      return <div>...loading</div>;
    } else if (isAuthenticated) {
      return <Home />;
    } else {
      return <PublicHome />;
    }
  };
  return <React.Suspense>{renderRoutes()}</React.Suspense>;
};
export default AppInitializer;
