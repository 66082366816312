/* eslint-disable max-len */
import * as React from 'react';
import {memo} from 'react';

const SvgComponent = ({isActive}: {isActive: boolean}) => (
  <svg
    style={{width: '26px'}}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60">
    <title>ChallengesFeed</title>
    <polygon
      fill={isActive ? '#E33A34' : '#3c4968'}
      points="60 30 0 60 0 0 60 30"
    />
  </svg>
);

const ChallengesFeedIcon = memo(SvgComponent);
export default ChallengesFeedIcon;
