/* eslint-disable max-len */
// @ts-ignore
import SFProRegular from '@assets/fonts/SFProDisplay/SFProDisplay-Regular.ttf';
// @ts-ignore
// import SFProMedium from '../../assets/fonts/SFProDisplay/SFProDisplay-Medium.ttf';
// @ts-ignore
// import SFProBold from '../../assets/fonts/SFProDisplay/SFProDisplay-Bold.ttf';

// const sfPro = {
//   fontFamily: 'SFProRegular',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     url(${SFProRegular})
//   `
// };
// const sfProMedium = {
//   fontFamily: 'SFProMedium',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     url(${SFProMedium})
//   `
// };
// const sfProBold = {
//   fontFamily: 'SFProBold',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     url(${SFProBold})
//   `
// };

export default {
  styleOverrides: `
    @font-face {
      font-family: 'SFProRegular';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: local('Raleway'), local('Raleway-Regular'), url(${SFProRegular}) format('woff2');
      unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
      U+FEFF;
    }
  `
};
