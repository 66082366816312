import {decrypt, encrypt} from './LocalStorage';

class Storage {
  static KEYS = {
    APP_VERSION: 'app-version',
    AUTH_TOKEN: 'AUTH_TOKEN',
    PORTAL: 'PORTAL',
    SELECTED_MODULE_NAME: 'SELECTED_MODULE_NAME',
    SELECTED_CHECK_BOX: 'SELECTED_CHECKBOX_DATA',
    SELECTED_CHECKBOX_LIST: 'SELECTED_CHECKBOX_LIST',
    SELECTED_LOGIN_TYPE: 'SELECTED_LOGIN_TYPE',
    IS_AZURE_AUTHENTICATED: 'IS_AZURE_AUTHENTICATED',
    EPIC_TOKEN_URL: 'EPIC_TOKEN_URL',
    DOC_ID: 'DOC_ID',
    USER_GROUP: 'USER_ACCESS',
    EPIC_PATIENT_ID: 'EPIC_PATIENT_ID',
    AUDIT_IDENTIFIER: 'AUDIT_IDENTIFIER',
    AUDIT_IDENTIFIER_TYPE: 'AUDIT_IDENTIFIER_TYPE',
    AUDIT_LEGACY_APP: 'AUDIT_LEGACY_APP'
  };

  static setItem = (key: string, value: string): void => {
    if (!value) {
      value = '';
    }
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(encrypt(key), encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));
    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }
    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;
    while (i--) {
      try {
        if (decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }
    return '';
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static clear = (): void => {
    localStorage.clear();
  };
}

export default Storage;
