import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import './SchoolSignUpForm.scss';
import {useForm, Controller} from 'react-hook-form';
import {FormControl, FormHelperText} from '@mui/material';
import {SnackBarConfig} from '@utils//SnackBarConfig';
import {useSnackbar} from 'notistack';
import {
  RegionServiceMapper,
  SchoolSignUpParams
} from 'src/services/service-mappers/PublicServiceMapper';
import NextUpPerformanceFullLogo from '@components/icons-component/NextUpPerformanceFullLogo';
import TextFieldComponent from '@components/mui-library-components/TextFieldComponent';
import ButtonComponent from '@components/mui-library-components/ButtonComponent';
import LinkComponent from '@components/mui-library-components/LinkComponent';
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from '@constants/RouteConstants';
import {useNavigate} from 'react-router-dom';
import SelectComponent from '@components/mui-library-components/SelectComponent';
// import RadioComponent from '@components/mui-library-components/RadioComponent';
import {
  isSchoolExistService,
  loginService,
  regionService,
  schoolUserService,
  userService,
  stateService
} from 'src/services/service-handlers/PublicService';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/store/Store';
import {schoolTypesData} from '@constants/AppConstants';
import {login, userLoginType} from '@modules/home/state/auth/AuthStateSlice';
import AuthUtils from '@utils//AuthUtils';
import CloseIconComponent from '@components/icons-component/CloseIconComponent';
import {
  emailDomain,
  phoneNumberStringFormatter
} from '../../../../utils/HelperUtils';
import CircularProgressComponent from '@components/mui-library-components/CircularProgressComponent';
import TransComponent from '@components/mui-library-components/TransComponent';
import {validateOrganizationSignUpform} from './SignUpFormValidator';

const SchoolSignUpForm = (): React.ReactElement => {
  const {t} = useTranslation();
  const clientRegistrationParams = useSelector((stateValue: RootState) => {
    return stateValue?.auth?.clientRegistration;
  });
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  // const [freeTrialPlan, setFreeTrialPlan] = useState<boolean>(true);
  const [errorText, setErrorText] = useState<string>('');

  const [states, setStates] = useState({
    response: [],
    value: '',
    id: -1
  });
  const [regions, setRegions] = useState({
    response: [],
    value: '',
    id: -1
  });
  const [schoolType, setSchoolType] = useState({
    label: '',
    value: t('PRIVATE.HIGH_SCHOOL_TEXT')
  });
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false); // To show the loader in signup button

  type Data = {
    school_name: string;
    school_address: string;
    contact_number: string;
    type: string;
    state: string;
    region: string;
    free_trial: boolean;
    yearly_subscription: boolean;
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: {errors}
  } = useForm<Data>({
    mode: 'all',
    resolver: async values => {
      const type: string = watch('type')
        ? watch('type')
        : t('PRIVATE.HIGH_SCHOOL_TEXT');
      const number: string = watch('contact_number');
      return {
        values,
        errors: validateOrganizationSignUpform(values, type, number)
      };
    }
  });
  const phoneNumberValue = watch('contact_number');
  const schoolTypes = schoolTypesData(t);
  const selectedStateValue = watch('state');
  const selectedRegion = watch('region');
  const selectedType: string = watch('type')
    ? watch('type')
    : t('PRIVATE.HIGH_SCHOOL_TEXT');
  React.useEffect(() => {
    stateService()
      .then(res => {
        setStates({...states, response: res});
      })
      .catch(err => {
        enqueueSnackbar((err as Error).message, SnackBarConfig('e'));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (states.value || selectedStateValue) {
      states.response.map((data: RegionServiceMapper) => {
        if (data.name === selectedStateValue) {
          regionService(data).then(res => {
            setStates({...states, id: data.id});
            setRegions({...regions, response: res});
          });
        }
      });
    }
  }, [states.value, selectedStateValue]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    regions.response.map(
      (data: {name: string | number | readonly string[]; id: number}) => {
        if (data.name === selectedRegion) {
          setRegions({
            ...regions,
            value: selectedRegion as string,
            id: data.id
          });
        }
      }
    );
  }, [selectedRegion]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    schoolTypes.map(data => {
      if (data.label === selectedType) {
        setSchoolType({
          ...schoolType,
          label: selectedType as string,
          value: data.value
        });
      }
    });
  }, [selectedType]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (phoneNumberValue !== undefined) {
      const formattedNumber = phoneNumberStringFormatter(phoneNumberValue);
      setPhoneNumber(formattedNumber);
    }
  }, [phoneNumberValue]);
  // const handlePlanChange = () => {
  //   setFreeTrialPlan(!freeTrialPlan);
  // };
  const handleFormSubmit = async (values: SchoolSignUpParams) => {
    try {
      setLoader(true);
      setStates({...states, value: values.state as string});

      if (!values.school_name || values.school_name === '') {
        setLoader(false);
        setErrorText(t('PRIVATE.SCHOOL_NAME_BLANK'));
      } else if (!values.school_address || values.school_address === '') {
        setLoader(false);
        setErrorText(t('PRIVATE.ADDRESS_BLANK'));
      } else if (!values.state || values.state === '') {
        setLoader(false);
        setErrorText(t('PRIVATE.STATE_BLANK'));
      } else if (!values.region || values.region === '') {
        setLoader(false);
        setErrorText(t('PRIVATE.REGION_BLANK'));
      } else if (!selectedType) {
        setLoader(false);
        setErrorText(t('PRIVATE.TYPE'));
      } else if (!phoneNumber || phoneNumber === '') {
        setLoader(false);
        setErrorText(t('PRIVATE.CONTACT_NUMBER_BLANK'));
      } else {
        const response = await isSchoolExistService(values);
        if (response?.status === 200) {
          const userInfo = {
            confirm: clientRegistrationParams.confirm,
            email: clientRegistrationParams.email,
            password: clientRegistrationParams.password,
            type: clientRegistrationParams.type,
            username: clientRegistrationParams.username,
            status: t('PRIVATE.ACTIVE'),
            user_handle: clientRegistrationParams.userHandle.toLowerCase()
          };
          const school = {
            account_type: 'free',
            address: values.school_address,
            email_domain: emailDomain(userInfo.email),
            name: values.school_name,
            phone: phoneNumber,
            region: regions.id,
            state: states.id,
            type: schoolType.value || t('PRIVATE.HIGH_SCHOOL_TEXT')
          };

          if (
            school.account_type === t('PRIVATE.PAID') &&
            schoolType?.value === t('PRIVATE.HIGH_SCHOOL_TEXT')
          ) {
            navigate(`/${PUBLIC_ROUTES.PAYMENT}`);
            setLoader(false);
          } else {
            const userResponse = await userService({school, userInfo});
            if (
              userResponse?.message === t('PRIVATE.USER_CREATED_SUCCESSFULLY')
            ) {
              const loginResponse = await loginService(userInfo);
              try {
                if (loginResponse.user.id) {
                  const activeParams = {
                    user_id: loginResponse.user.id,
                    active: true,
                    page: 0,
                    size: 20
                  };
                  await schoolUserService(activeParams);
                  const inActiveParams = {
                    user_id: loginResponse.user.id,
                    active: false,
                    page: 0,
                    size: 20
                  };
                  await schoolUserService(inActiveParams);
                  const token: string = loginResponse.token;
                  dispatch(login({token}));
                  // dispatch userLoginType
                  dispatch(
                    userLoginType({
                      type: loginResponse.user?.type,
                      id: loginResponse?.user?.id
                    })
                  );
                  AuthUtils.setAuthToken(
                    loginResponse.token,
                    PUBLIC_ROUTES.CLIENT
                  );
                  navigate(`/${PRIVATE_ROUTES.CLIENT}`);
                  setLoader(false);
                }
              } catch (e) {
                setErrorText((e as Error).message);
                setLoader(false);
              }
            }
          }
        } else {
          setLoader(false);
          enqueueSnackbar(
            t('PRIVATE.SCHOOL_NAME_AVAILABLE'),
            SnackBarConfig('e')
          );
        }
      }
      // navigate(`/${PUBLIC_ROUTES.PAYMENT}`);
    } catch (e) {
      setLoader(false);
      enqueueSnackbar((e as Error).message, SnackBarConfig('e'));
    }
  };
  const navigateToSignUpPage = () => {
    navigate(`/${PUBLIC_ROUTES.SIGNUP}`);
  };

  // To determine the organizationLabel
  const organizationLabel = (selectedLabel: string): string => {
    return selectedLabel.toLowerCase().includes('college')
      ? 'College'
      : selectedLabel.toLowerCase().includes('team')
      ? 'Team'
      : selectedLabel.toLowerCase().includes('company')
      ? 'Company'
      : 'Gym';
  };

  return (
    <div className="school-sign-up-form">
      <div className="next-up-logo">
        <NextUpPerformanceFullLogo />
      </div>
      <div className="header-input-field-div">
        <legend className="legend-header">
          {t('PRIVATE.LOG_IN.SCHOOL_SIGNUP')}
        </legend>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div
            className={[
              'item-parent-div',
              'organization-type-bottom-fields'
            ].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">{t('PRIVATE.LOG_IN.TYPE')}</label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={['sign-up-input-field', 'sign-up-select-input'].join(
                ' '
              )}
              error={!!errors.type}>
              <Controller
                name="type"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <SelectComponent
                    className="select-component"
                    value={value ? value : 'High School'}
                    onChange={onChange}
                    selectOptions={schoolTypes}
                    placeholder={'Type'}
                    defaultText="Type"
                    extension={'label'}
                  />
                )}
              />
              {errors.type && (
                <FormHelperText className="error-text-component">
                  {t(errors.type as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="item-parent-div">
            <div className="item-label-input">
              <label className="input-label">
                <TransComponent
                  i18nKeyText={t('PRIVATE.LOG_IN.TEAM_OR_SCHOOL_NAME')}
                  values={{
                    organizationName: selectedType
                      .toLowerCase()
                      .includes('school')
                      ? 'School'
                      : organizationLabel(selectedType)
                  }}
                  components={[<strong key={'X'} />]}
                />
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.school_name}>
              <Controller
                name="school_name"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.school_name ? true : false}
                    type="text"
                    className="input-field-styles"
                    placeholder={
                      selectedType.toLowerCase().includes('school')
                        ? 'School Name'
                        : organizationLabel(selectedType) + ' Name'
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              school_name: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.school_name && (
                <FormHelperText className="error-text-component">
                  <TransComponent
                    i18nKeyText={t('VALIDATORS.ORGANIZATION_NAME_REQUIRED')}
                    values={{
                      organizationName: selectedType
                        .toLowerCase()
                        .includes('school')
                        ? 'School'
                        : organizationLabel(selectedType)
                    }}
                    components={[<strong key={'X'} />]}
                  />
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                <TransComponent
                  i18nKeyText={t('PRIVATE.LOG_IN.SCHOOL_ADDRESS')}
                  values={{
                    organizationName: selectedType
                      .toLowerCase()
                      .includes('school')
                      ? 'School'
                      : organizationLabel(selectedType)
                  }}
                  components={[<strong key={'X'} />]}
                />
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.school_address}>
              <Controller
                name="school_address"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.school_address ? true : false}
                    type="text"
                    className="input-field-styles"
                    placeholder={
                      selectedType.toLowerCase().includes('school')
                        ? 'School Address'
                        : organizationLabel(selectedType) + ' Address'
                    }
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              school_address: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.school_address && (
                <FormHelperText className="error-text-component">
                  <TransComponent
                    i18nKeyText={t('VALIDATORS.ORGANIZATION_ADDRESS_REQUIRED')}
                    values={{
                      organizationName: selectedType
                        .toLowerCase()
                        .includes('school')
                        ? 'School'
                        : organizationLabel(selectedType)
                    }}
                    components={[<strong key={'X'} />]}
                  />
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={'state-region-fields'}>
            <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
              <div className="item-label-input">
                <label className="input-label">
                  {t('PRIVATE.LOG_IN.STATE')}
                </label>
              </div>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={['sign-up-input-field', 'sign-up-select-input'].join(
                  ' '
                )}
                error={!!errors.state}>
                <Controller
                  name="state"
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <SelectComponent
                      className="select-component"
                      value={value ? value : 'State'}
                      onChange={onChange}
                      selectOptions={states.response}
                      placeholder={'State'}
                      defaultText="State"
                      extension={'name'}
                    />
                  )}
                />
                {errors.state && (
                  <FormHelperText className="error-text-component">
                    {t(errors.state as unknown as string)}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
              <div className="item-label-input">
                <label className="input-label">
                  {t('PRIVATE.LOG_IN.REGION')}
                </label>
              </div>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={['sign-up-input-field', 'sign-up-select-input'].join(
                  ' '
                )}
                error={!!errors.region}>
                <Controller
                  name="region"
                  control={control}
                  render={({field: {onChange, onBlur, value}}) => (
                    <SelectComponent
                      className="select-component"
                      value={watch('state') ? value : 'Region'}
                      onChange={onChange}
                      selectOptions={regions.response}
                      placeholder={'Region'}
                      defaultText="Region"
                      extension={'name'}
                    />
                  )}
                />
                {errors.region && (
                  <FormHelperText className="error-text-component">
                    {t(errors.region as unknown as string)}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>

          <div className={['item-parent-div', 'bottom-fields'].join(' ')}>
            <div className="item-label-input">
              <label className="input-label">
                {t('PRIVATE.LOG_IN.CONTACT_NUMBER')}
              </label>
            </div>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className="sign-up-input-field"
              error={!!errors.contact_number}>
              <Controller
                name="contact_number"
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextFieldComponent
                    errorOcurred={errors.contact_number ? true : false}
                    type="text"
                    inputProps={{
                      name: 'contact_number',
                      control: control,
                      international: true,
                      defaultCountry: 'US',
                      maxLength: 10
                    }}
                    className="input-field-styles"
                    placeholder="Contact Number"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (
                        <CloseIconComponent
                          value={value}
                          className="close-icon"
                          onClick={() => {
                            reset(formValues => ({
                              ...formValues,
                              contact_number: ''
                            }));
                          }}
                        />
                      )
                    }}
                  />
                )}
              />
              {errors.contact_number && (
                <FormHelperText className="error-text-component">
                  {t(errors.contact_number as unknown as string)}
                </FormHelperText>
              )}
            </FormControl>
          </div>

          {/* Commented the below lines of code as we are not using any paid plans now */}
          {/* {selectedType !== t('PRIVATE.COLLEGE') &&
            selectedType !== t('PRIVATE.PRO_TEAM') && (
              <div
                className={[
                  'item-label-input',
                  'radio-buttons-label-style'
                ].join(' ')}>
                <label className="input-label">
                  {t('PRIVATE.LOG_IN.SELECT_PLAN')}
                </label>
              </div>
            )} */}
          {/* {selectedType !== t('PRIVATE.COLLEGE') &&
            selectedType !== t('PRIVATE.PRO_TEAM') && (
              <div className={'select-plan-fields'}>
                <div
                  className={[
                    'item-parent-div',
                    'bottom-fields',
                    'plan-label-fields'
                  ].join(' ')}>
                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    className={[
                      'sign-up-input-field',
                      'sign-up-select-input'
                    ].join(' ')}>
                    <Controller
                      name="free_trial"
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RadioComponent
                          checked={freeTrialPlan}
                          onChange={handlePlanChange}
                          value={'free trial'}
                          color="success"
                        />
                      )}
                    />
                  </FormControl>
                  <span
                    className={['plan-label', 'radio-label-font-styles'].join(
                      ' '
                    )}>
                    {t('PRIVATE.LOG_IN.FREE_TRIAL')}
                  </span>
                </div>
                <div
                  className={[
                    'item-parent-div',
                    'bottom-fields',
                    'plan-label-fields'
                  ].join(' ')}>
                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    className={[
                      'sign-up-input-field',
                      'sign-up-select-input'
                    ].join(' ')}>
                    <Controller
                      name="yearly_subscription"
                      control={control}
                      render={({field: {onChange, onBlur, value}}) => (
                        <RadioComponent
                          checked={!freeTrialPlan}
                          onChange={handlePlanChange}
                          value={'subscription'}
                          color="success"
                        />
                      )}
                    />
                  </FormControl>
                  <span
                    className={['plan-label', 'radio-label-font-styles'].join(
                      ' '
                    )}>
                    {t('PRIVATE.LOG_IN.YEARLY_SUBSCRIPTION')}
                  </span>
                </div>
              </div>
            )} */}
          {errorText && (
            <div
              className={['status-error', 'status-error-local-styles'].join(
                ' '
              )}>
              {errorText}
            </div>
          )}
          <ButtonComponent
            type="submit"
            onClick={handleSubmit(handleFormSubmit)}
            variant="contained"
            disabled={loader}
            className={['login-button-styles', 'login-button-font-styles'].join(
              ' '
            )}>
            {loader ? (
              <CircularProgressComponent className="circular-progress-color" />
            ) : (
              t('PRIVATE.CREATE')
            )}
          </ButtonComponent>
          <div className={['link-text', 'link-text-local-styles'].join(' ')}>
            <LinkComponent
              className="link-text"
              component="button"
              onClick={navigateToSignUpPage}>
              {t('PRIVATE.BACK_TO_SIGNUP_PAGE')}
            </LinkComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SchoolSignUpForm;
