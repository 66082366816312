import {PUBLIC_ROUTES} from '@constants/RouteConstants';
import Login from '@modules/public/main/login/Login';
import ForgotPassword from '@modules/public/main/forgotpassword/ForgotPasswordPage';
import React from 'react';
import Logout from '@modules/public/main/logout/Logout';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import SignUpForm from '@modules/public/main/signupform/SignUpForm';
import SchoolSignUpForm from '@modules/public/main/signupform/SchoolSignUpForm';
import PaymentForm from '@modules/public/main/signupform/PaymentForm';
import AdminSignup from '@modules/home/main/admin-signup/AdminSignup';
import ResetPassword from '@modules/public/main/resetpassword/ResetPassword';
import UpdateUser from '@modules/public/main/updateuser/UpdateUser';

const PublicRoutes = (): React.ReactElement => {
  return (
    <Router>
      <Routes>
        <Route
          path={`${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.LOGIN}`}
          element={<Login pathName={PUBLIC_ROUTES.ADMIN} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`}
          element={<Login pathName={PUBLIC_ROUTES.CLIENT} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.CONTRACT}/${PUBLIC_ROUTES.LOGIN}`}
          element={<Login pathName={PUBLIC_ROUTES.CONTRACT} />}
        />
        <Route
          path={`/${PUBLIC_ROUTES.FORGOT}`}
          element={<ForgotPassword pathName={PUBLIC_ROUTES.ADMIN} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.FORGOT}`}
          element={<ForgotPassword pathName={PUBLIC_ROUTES.CLIENT} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.CONTRACT}/${PUBLIC_ROUTES.FORGOT}`}
          element={<ForgotPassword pathName={PUBLIC_ROUTES.CONTRACT} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.ADMIN}`}
          element={<Navigate to={`${PUBLIC_ROUTES.LOGIN}`} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.CLIENT}`}
          element={<Navigate to={`${PUBLIC_ROUTES.LOGIN}`} />}
        />
        <Route
          path={`${PUBLIC_ROUTES.CONTRACT}`}
          element={<Navigate to={`${PUBLIC_ROUTES.LOGIN}`} />}
        />
        <Route path={`${PUBLIC_ROUTES.SIGNUP}`} element={<SignUpForm />} />
        <Route
          path={`${PUBLIC_ROUTES.SCHOOL}`}
          element={<SchoolSignUpForm />}
        />
        <Route
          path={`/${PUBLIC_ROUTES.ADMIN}/${PUBLIC_ROUTES.SIGNUP}`}
          element={<AdminSignup />}
        />
        <Route path={`${PUBLIC_ROUTES.PAYMENT}`} element={<PaymentForm />} />
        <Route path={`${PUBLIC_ROUTES.LOGOUT}`} element={<Logout />} />
        <Route
          path={`/`}
          element={
            <Navigate to={`${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`} />
          }
        />
        <Route
          path={`${PUBLIC_ROUTES.RESET_PASSWORD}`}
          element={<ResetPassword />}
        />
         <Route
          path={`${PUBLIC_ROUTES.USER}/${PUBLIC_ROUTES.UPDATE}`}
          element={<UpdateUser />}
        />
        <Route
          path={'*'}
          element={
            <Navigate to={`/${PUBLIC_ROUTES.CLIENT}/${PUBLIC_ROUTES.LOGIN}`} />
          }
        />
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
