import React, {ReactElement, ReactNode} from 'react';
import {Trans} from 'react-i18next';

interface TransComponentProps {
  i18nKeyText: string;
  values: object | undefined;
  components:
    | (readonly ReactNode[] &
        (
          | readonly ReactElement<string>[]
          | {
              readonly [tagName: string]: ReactElement<string | ReactElement>;
            }
        ))
    | undefined;
}
const TransComponent = (props: TransComponentProps): React.ReactElement => {
  const {i18nKeyText, values, components} = props;
  return (
    <Trans i18nKey={i18nKeyText} values={values} components={components} />
  );
};

export default TransComponent;
