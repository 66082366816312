import { MAX_CHAR_LIMIT_FOR_USER_HANDLE } from '@constants/AppConstants';
import { isValidUserHandle } from '@utils//HelperUtils';
import {emailValidator} from '@utils//Validate';
import {FieldValues} from 'react-hook-form';

export const validateSignUpform = (values: FieldValues): FieldValues => {
  let errors: FieldValues = {
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    user_handle: ''
  };
  if (!values.name || !values.name.replace(/\s/g, '').length) {
    errors.name = 'VALIDATORS.NAME_REQUIRED';
  }
  if (!values.email || !values.email.replace(/\s/g, '').length) {
    errors.email = 'VALIDATORS.EMAIL_REQUIRED';
  } else if (!emailValidator(String(values.email).toLowerCase())) {
    errors.email = 'VALIDATORS.EMAIL_NOT_VALID';
  }
  if (!values.password || !values.password.replace(/\s/g, '').length) {
    errors.password = 'VALIDATORS.PASSWORD_REQUIRED';
  }
  if (values.password !== values.confirm_password) {
    errors.confirm_password = 'VALIDATORS.PASSWORD_MATCH';
  }
  if (!values.user_handle || !values.user_handle.replace(/\s/g, '').length) {
    errors.user_handle = 'VALIDATORS.USER_HANDLE_REQUIRED';
  }
  if (values.user_handle && values.user_handle.length > MAX_CHAR_LIMIT_FOR_USER_HANDLE) {
    errors.user_handle = 'VALIDATORS.USER_NAME_MAX_LENGTH';
  }
  if (values.user_handle && !isValidUserHandle(values.user_handle)) {
    errors.user_handle = 'VALIDATORS.USER_NAME_CHARATERS';
  }
  if (
    !errors.email &&
    !errors.name &&
    !errors.password &&
    !errors.confirm_password &&
    !errors.user_handle
  ) {
    errors = {};
  }
  return errors;
};

export const validateOrganizationSignUpform = (values: FieldValues,selectedType: string,phoneNumber: string): FieldValues => {
  let errors: FieldValues = {
    type: '',
    school_name: '',
    school_address: '',
    state: '',
    region: '',
    contact_number: ''
  };
  if (!selectedType || !selectedType.replace(/\s/g, '').length) {
    errors.type = 'VALIDATORS.TYPE_REQUIRED';
  }
  if (!values.school_name || !values.school_name.replace(/\s/g, '').length) {
    errors.school_name = 'VALIDATORS.ORGANIZATION_NAME_REQUIRED';
  }
  if (!values.school_address || !values.school_address.replace(/\s/g, '').length) {
    errors.school_address = 'VALIDATORS.ORGANIZATION_ADDRESS_REQUIRED';
  }
  if (!values.state || !values.state.replace(/\s/g, '').length) {
    errors.state = 'VALIDATORS.STATE_REQUIRED';
  }
  if (!values.region || !values.region.replace(/\s/g, '').length) {
    errors.region = 'VALIDATORS.REGION_REQUIRED';
  }
  if (!phoneNumber || !phoneNumber.replace(/\s/g, '').length) {
    errors.contact_number = 'VALIDATORS.CONTACT_REQUIRED';
  }
  if(phoneNumber && phoneNumber.length < 10) {
    errors.contact_number = 'VALIDATORS.CONTACT_INVALID';
  }
  if (
    !errors.type &&
    !errors.school_name &&
    !errors.school_addressd &&
    !errors.state &&
    !errors.region &&
    !errors.contact_number
  ) {
    errors = {};
  }
  return errors;
};