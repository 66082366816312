import axios from 'axios';
import {config} from '../config/environment/environment';

class Services {
  // Call at the very beginning
  static init(): void {
    axios.defaults.timeout = config.REQ_TIME_OUT;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.baseURL = config.BASE_URL;
  }

  // Call after authentication is done and make required changes
  static initAfterAuth(appConfig: {token: string | null}): void {
    axios.defaults.headers.common['authorization'] = `Bearer ${
      appConfig?.token || ''
    }`;
  }

  // The generic handling of errors
  static genericErrorHandler(error: {response: {data: string}}): void {
    if (error && error.response && error.response.data) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
}

export default Services;
